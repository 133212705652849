import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgLogo from '../generated/svg/logo'
import SvgLogoWithCopy from '../generated/svg/logo-with-copy'
import SvgLogoWithCopy2 from '../generated/svg/logo-with-copy2'

const Outer = styled.div`
  text-align: center;
  font-size: min(3vw, 1rem);
  font-weight: 300;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem ;
  letter-spacing: 0.1em;
`

const Title = styled.div`
  font-weight: 700;
`

const SubTitle = styled.div``

const Label = styled.div``

const NewLabel = styled.div`
  color: #fff;
  padding: 0 0.4rem;
  white-space: nowrap;
  span {
    font-weight: 700;
    padding: 0 0.4rem;
  }
`

const LogoContainer = styled.div`
  position: relative;
`

const BackGround = styled.div`
  img {
    width: 100%;
  }
`

const Logo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    height: auto;
    width: 9.5rem;
  }
`

const SeriesTitle = styled.div`
  letter-spacing: 0.125em;
  font-weight: 600;
  padding-top: 1.5rem;
  font-size: 0.875rem;
  line-height: 0.15em;
`

const Detail = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 0 1.5rem;
  border-bottom: 1px solid #717071;
  letter-spacing: 0.125em;
`

const Years = styled.div`
  position: relative;
  padding: 0 1rem;
  &::after {
    position: absolute;
    content: "";
    width: 1px;
    right: 0;
    top: 15%;
    height: 80%;
    background-color: currentColor;
  }
`

const Patterns = styled.div`
  position: relative;
  padding: 0 1rem;
  &::after {
    position: absolute;
    content: "";
    width: 1px;
    right: 0;
    top: 15%;
    height: 80%;
    background-color: currentColor;
  }
`

const Colors = styled.div`
padding: 0 1rem;
`

type MobileSeriesHeaderProps = {
  title: string;
  subtitle?: string;
  years: string;
  isNew?: boolean;
  patterns: string;
  colors: string;
  imgSrc: string;
  logoColor: string;
  seriesTitle?: string;
  logoWithCopy?: boolean;
  logoWithCopy2?: boolean
}
export const MobileSeriesHeader: FunctionComponent<MobileSeriesHeaderProps> = ({title,subtitle,years,isNew,patterns,colors,imgSrc,logoColor,seriesTitle, logoWithCopy, logoWithCopy2}) => {
  return <Outer>
    <TitleRow>
      <Title>{title}</Title>
      {
        subtitle &&
        <SubTitle>{subtitle}</SubTitle>
      }
      <Label>WALLCOVERLINGS</Label>
      {
        isNew &&
        <NewLabel>|<span>NEW</span>|</NewLabel>
      }
    </TitleRow>
    <LogoContainer>
      <BackGround>
        <img src={imgSrc} />
      </BackGround>
      <Logo style={{color: logoColor}}>
        {logoWithCopy ? <SvgLogoWithCopy /> : logoWithCopy2 ? <SvgLogoWithCopy2 /> : <SvgLogo />}
        {
          seriesTitle &&
          <SeriesTitle>{seriesTitle}</SeriesTitle>
        }
      </Logo>
    </LogoContainer>
    <Detail>
      <Years>{years}</Years>
      <Patterns>{patterns} PATTERNS</Patterns>
      <Colors>{colors} COLORS</Colors>
    </Detail>

  </Outer>
}