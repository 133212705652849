import classNames from 'classnames'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { scrollTo } from '../utils/scroll-to'

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #C8C6C388;
  backdrop-filter: blur(1rem);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
  &.open {
    opacity: 1;
    pointer-events: all;
  }
  margin-top: calc(80px + 5rem);
  @media (max-width: 1024px){
    margin-top: calc(68px + 3.5rem);
  }
  @media (max-width: 768px){
    margin-top: calc(60px + 3.5rem);
  }
`

const Label = styled.div`
  font-size: 0.75rem;
  padding: 1rem;
  border-bottom: 1px solid #717071;
  letter-spacing: 0.03em;
`

const MenuOuter = styled.div`
  display: grid;
`

const MenuContainer = styled.a`
  min-height: min-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: baseline;
  font-size: 4vw;
  border-bottom: 1px solid #717071;
  letter-spacing: 0.025em;
  padding: 1.3rem 1rem;
  font-weight: 400;
`

const Title = styled.div`
  font-size: 4.5vw;
  font-weight: 800;
  line-height: 1.5;
`

const Text = styled.div`
  font-size: 3.75vw;
  line-height: 1.5;
`

const NewLabel = styled(Text)`
  font-weight: 700;
  color: #fff;
`

const SubTitle = styled(Text)`
`

const Label2 = styled(Text)`
`

const Years = styled(Text)`
`

const Patterns = styled(Text)`
`

const Colors = styled(Text)`
`



type MobileMenuProps = {
  open: boolean
  onClose: () => void
}
export const MobileMenu: FunctionComponent<MobileMenuProps> = ({open, onClose}) => {
  return <Outer className={classNames({open})}>
    <Label>Publication Catalog :</Label>
    <MenuOuter>
      <MenuContainer href="#reserve" onClick={e => {
        scrollTo(e)
        onClose()
      }}>
        <Title>RéSERVE</Title>
        <NewLabel>NEW</NewLabel>
        <Label2>WALLCOVERLINGS</Label2>
        <Patterns>7 PATTERNS</Patterns>
        <Years>2024-2027</Years>
        <Colors>19 COLORS</Colors>
      </MenuContainer>
      <MenuContainer href="#fine" onClick={e => {
        scrollTo(e)
        onClose()
      }}>
        <Title>FINE</Title>
        <NewLabel></NewLabel>
        <Label2>WALLCOVERLINGS</Label2>
        <Patterns>10 PATTERNS</Patterns>
        <Years>2023-2026</Years>
        <Colors>33 COLORS</Colors>
      </MenuContainer>
      <MenuContainer href="#faith" onClick={e => {
        scrollTo(e)
        onClose()
      }}>
        <Title>FAITH</Title>
        <NewLabel></NewLabel>
        <SubTitle>CONTRACT</SubTitle>
        <Patterns>19 PATTERNS</Patterns>
        <Label2>WALLCOVERLINGS</Label2>
        <Colors>91 COLORS</Colors>
        <Years>2022-2025</Years>
      </MenuContainer>
    </MenuOuter>
  </Outer>
}