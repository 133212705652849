import React, { FunctionComponent } from 'react'
import styled from 'styled-components'


const Outer = styled.div`
  width: max-content;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.75;
  letter-spacing: 0.1em;
`

const TitleRow = styled.div`
  display: flex;
  gap: 0.4rem;

`

const Title = styled.div`
  font-weight: 700;
`
const NewLabel = styled.div`
  color: #fff;

  span{
      margin: 0 0.5rem;
      font-weight: 700;
   }
   `

const SubTitle = styled.div``

const Label2 = styled.div``

type SeriesHeaderProps = {
  title: string;
  subtitle?: string;
  isNew?: boolean;
  align: "left" | "right";
}
export const SeriesHeader: FunctionComponent<SeriesHeaderProps> = ({title,subtitle,isNew,align}) => {
  return <Outer>
    <TitleRow style={{justifyContent: align === "right" ? 'flex-end' : undefined}}>
      <Title>{title}</Title>
      {
        isNew &&
        <NewLabel>|<span>NEW</span>|</NewLabel>
      }
    </TitleRow>
      <SubTitle>{subtitle}</SubTitle>
      <Label2>WALLCOVERLINGS</Label2>


  </Outer>
}