import classNames from 'classnames'
import React, { FunctionComponent, RefObject } from 'react'
import styled from 'styled-components'
import { Product } from '../types/product'
import { lw } from '../utils/lw'
import { Image } from './image'

const Outer = styled.div<{columns: number}>`
  display: grid;
  grid-template-columns: repeat(${({columns}) => columns}, ${lw(55)});
  gap: ${lw(13)} ${lw(18)};
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 0.25rem;
  margin: -0.25rem;
  place-content: start;
  @media (max-width: 50rem){
    grid-template-columns: repeat(${({columns}) => columns}, 1fr);
    gap: 0.6rem;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0.25rem;
    border-radius: 0.125rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: #ccc;
  }
  &::-webkit-scrollbar-thumb {
    background: currentcolor;
    border-radius: 0.125rem;
  }
`

const Item = styled.div`
  display: block;
`

const ThumbnailContainer = styled.div`
  img {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    ${Item}:hover &,
    ${Item}.selected & {
      outline: 1px solid #000;
      outline-offset: 0.125rem;
    }
    @media (max-width: 50rem){
      background-color: #fff;
    }
  }
`

const ItemLabel = styled.a`
  display: block;
  font-size: min(${lw(10)}, 0.6rem);
  padding-top: 0.3rem;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.125rem;
  }
  @media (max-width: 50rem){
    font-size: 0.7rem;
  }
`

type ProductListProps = {
  products: Product[]
  onSelect?: (name: string) => void
  columns: number
  onThumbnailClick?: (name: string) => void
  onLabelClick?: (name: string) => void
  linkTarget: 'outer' | 'label'
  selected: string
  scrollOuterRef?: RefObject<HTMLDivElement>
}
export const ProductList: FunctionComponent<ProductListProps> = ({products, columns, onSelect, onLabelClick, onThumbnailClick, linkTarget, selected, scrollOuterRef}) => {
  return <Outer columns={columns} ref={scrollOuterRef}>
    {
      products.map(product => {
        return <Item key={product.name} onMouseEnter={() => onSelect?.(product.name)} className={classNames({selected: product.name === selected})}>
          <ThumbnailContainer onClick={() => onThumbnailClick?.(product.name)}>
            <Image src={product.image50} width={100} height={100} />
          </ThumbnailContainer>
          <ItemLabel href={linkTarget === 'label' ? product.link : undefined} target="_blank" onClick={() => onLabelClick?.(product.name)}>{product.name ? `${product.name} ＞` : ''}</ItemLabel>
        </Item>
      })
    }
  </Outer>
}