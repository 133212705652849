import * as React from "react";
import type { SVGProps } from "react";
const SvgMenuButton = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={15}
    viewBox="0 0 30 15"
    {...props}
  >
    <g
      fill="none"
      stroke="#555"
      strokeMiterlimit={10}
      strokeWidth={3}
      data-name="Component 56 \u2013 5"
    >
      <path d="M0 1.5h30" data-name="Line 88" />
      <path d="M30 13.5H0" data-name="Line 89" />
    </g>
  </svg>
);
export default SvgMenuButton;
