import React, { createContext, FunctionComponent, ReactNode, useContext, useState } from 'react'

type PopupContext = {
  isOpen: boolean
  open: (callback: () => void) => void
  close: () => void
}
const PopupContext = createContext<PopupContext>({isOpen: false, open: () => {}, close: () => {}})

type PopupContextProviderProps = {
  children?: ReactNode
}
export const PopupContextProvider: FunctionComponent<PopupContextProviderProps> = ({children}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [callback, setCallback] = useState<{fn: (() => void) | undefined}>({fn: undefined})
  return <PopupContext.Provider value={{
    isOpen,
    open: newCallback => {
      setIsOpen(true)
      if(callback.fn){
        callback.fn()
      }
      setCallback({fn: newCallback})
    },
    close: () => {
      setIsOpen(false)
      if(callback.fn){
        callback.fn()
      }
      setCallback({fn: undefined})
    }
  }}>
    {children}
  </PopupContext.Provider>
}

export const usePopup = () => {
  const context = useContext(PopupContext)
  return context
}