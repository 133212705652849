import * as React from "react";
import type { SVGProps } from "react";
const SvgInactiveSpeaker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31.707}
    height={33.414}
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 31.707 33.414"
    {...props}
  >
    <g fill="none" stroke="currentcolor" data-name="Component 45 2">
      <g data-name="Group 1056">
        <path d="m22.854 32.207-11-11v-9l11-11z" data-name="Path 1070" />
        <path d="M3.854 12.207h6v9h-6z" data-name="Rectangle 397" />
      </g>
      <path d="m31.354 32.707-31-31" data-name="Line 62" />
    </g>
  </svg>
);
export default SvgInactiveSpeaker;
