import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { lw, mlw } from '../utils/lw'
import { Image } from './image'
import { Swiper, SwiperSlide } from 'swiper/react'
import SvgLogo from '../generated/svg/logo'
import { Autoplay, EffectFade } from 'swiper/modules'

const Outer = styled.div`
  margin-bottom: 1rem;
  position: relative;
`

const ImageOuter = styled.div`
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    position: relative;
  }
  .swiper {
    opacity: 0;
    transition: opacity 0.4s;
  }
  .swiper.swiper-initialized {
    opacity: 1;
  }
`

const ImageText = styled.div`
  font-size: min(3.5vw, 1rem);
  text-align: center;
  position: absolute;
  bottom: 70%;
  left: 0;
  right: 0;
  margin: auto;
  svg {
    height: 1.3rem;
    width: auto;
    margin-bottom: -0.25em;
    margin-right: 0.25em;
  }
`

const Text = styled.div`
  font-size: min(3.5vw, 0.9rem);
  line-height: 2;
  text-align: center;
  font-family: 'Noto Sans JP';
  font-weight: 300;
  margin-top: 1rem;
  svg {
    height: 1.1em;
    width: auto;
    margin-bottom: -0.25em;
    margin-right: 0.25em;
  }
`

type MobileConceptProps = {

}
export const MobileConcept: FunctionComponent<MobileConceptProps> = () => {
  return <Outer>
    <ImageOuter>
      <Swiper
        modules={[
        EffectFade,
        Autoplay
        ]}
        effect="fade"
        autoplay={{
        delay: 3000,
        }}
        speed={2000}
      >
        <SwiperSlide>
          <Image src="/pickup/process_100/images/mobile_top_01.jpg" width="375" height="480"/>
          <ImageText>
            <SvgLogo />
          </ImageText>
        </SwiperSlide>
        <SwiperSlide>
          <Image src="/pickup/process_100/images/mobile_top_02.jpg" width="375" height="480"/>
          <ImageText style={{color: 'white', filter: 'none'}}>
            <SvgLogo />
          </ImageText>
        </SwiperSlide>
        <SwiperSlide>
          <Image src="/pickup/process_100/images/mobile_top_03.jpg" width="375" height="480"/>
          <ImageText>
            <SvgLogo />
          </ImageText>
        </SwiperSlide>
        <SwiperSlide>
          <Image src="/pickup/process_100/images/mobile_top_04.jpg" width="375" height="480"/>
          <ImageText>
            <SvgLogo />
          </ImageText>
        </SwiperSlide>
        <SwiperSlide>
          <Image src="/pickup/process_100/images/mobile_top_05.jpg" width="375" height="480"/>
          <ImageText>
            <SvgLogo />
          </ImageText>
        </SwiperSlide>
      </Swiper>
    </ImageOuter>
    <Text>
      <SvgLogo /> は、「個性と洗練」をコンセプトに<br />
      デザインから仕上げまで、プロセスを大切につくられた<br />
      サンゲツのプライベートブランド。<br />
      ゆるぎない美意識をもつ人々に、<br />
      洗練された上質な暮らしを提案します。
    </Text>
  </Outer>
}