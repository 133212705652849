import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useBreakpoint } from '../hooks/use-break-point'
import { products } from '../products'
import { Image } from './image'
import { LiquidDiv, RelativeLiquidDiv } from './liquid-div'
import { MobileSeriesHeader } from './mobile-series-header'
import { ProductImage } from './product-image'
import { SeriesHeader } from './series-header1'
import { SeriesHeader2 } from './series-header2'

const Outer = styled.div`
  padding-top: calc(80px + 4.5rem);
  border-top: 1px solid #717071;
  @media (max-width: 1024px){
    padding-top: calc(68px + 4.5rem);
  }
  @media (max-width: 768px){
    padding-top: calc(60px + 4.5rem);
  }
  @media (max-width: 50rem){
    border-top: 0 none;
    padding-top: calc(60px + 3.5rem);
  }
`

const Inner = styled(RelativeLiquidDiv)`
`

const HeadInner2 = styled.div`
  transform: translateX(-50%);
`

const ProductImageContainer = styled(LiquidDiv)`
`

const ImageContainer = styled(LiquidDiv)`
  img {
    width: 100%;
    height: 100%;
  }
`

const Border = styled(LiquidDiv)`
`

const Video = styled.video`
  display: block;
  width: 100%;
  height: auto;
`


type FaithSectionProps = {

}
export const FaithSection: FunctionComponent<FaithSectionProps> = () => {
  const isMobile = useBreakpoint('50rem')
  return <Outer id="faith">
    <Inner width={1200} height={8265} mobileWidth={375} mobileHeight={8133.75 + 402 + 210}>
      {
        isMobile ? <>
          <MobileSeriesHeader
            title="FAITH"
            subtitle="CONTRACT"
            years="2022-2025"
            patterns="19"
            colors="91"
            imgSrc="/pickup/process_100/images/mobile_series_header_faith.jpg"
            logoColor="white"
            seriesTitle="CONTRACT SERIES"
          />
        </> : <>
          <LiquidDiv left={50} top={168 - 135} fadeIn fadeInDelay="0.2s">
            <SeriesHeader
              title="FAITH"
              subtitle="CONTRACT"
              align='left'
            />
          </LiquidDiv>
          <LiquidDiv left={275.55 + (280 / 2)} top={304.64 - 135} fadeIn fadeInDelay="0.2s">
            <HeadInner2>
              <SeriesHeader2
                seriesTitle="CONTRACT SERIES"
                years="2022-2025"
                patterns="19"
                colors="91"
              />
            </HeadInner2>
          </LiquidDiv>
          <ImageContainer left={816} top={135 - 135} width={384} height={700} fadeIn>
            <Image src="/pickup/process_100/images/cover_faith.jpg" width={384} height={700} />
          </ImageContainer>
        </>
      }
      <ProductImageContainer left={306} top={569 - 135} mobileLeft={90.57} mobileTop={498.53} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'SLOW AND MELLOW',
              nameJa: 'スロー アンド メロー'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_philosophy_01.jpg',
              width: 225,
              height: 420,
              mobileWidth: 192.86,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'TH32053'
                }
              ]
            }
          ]}
          products={products['SLOW AND MELLOW']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=15"
        />
      </ProductImageContainer>
      <ProductImageContainer left={50} top={1229 - 135} mobileLeft={195.86} mobileTop={987.48} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'SILKY VOICE',
              nameJa: 'シルキー ボイス'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_silky_voice_01.jpg',
              width: 180,
              height: 330,
              mobileWidth: 163.64,
              mobileHeight: 300,
              popup: true,
              labels: [
                {
                  label: 'TH32061'
                }
              ]
            }
          ]}
          products={products['SILKY VOICE']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=16"
        />
      </ProductImageContainer>
      <ProductImageContainer left={245} top={1229 - 135} mobileLeft={14.5} mobileTop={1327.48} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'BEFORE DAWN',
              nameJa: 'ビフォー ドーン'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_before_dawn_01.jpg',
              width: 420,
              height: 330,
              mobileWidth: 345,
              mobileHeight: 250,
              popup: true,
              labels: [
                {
                  label: 'Left: TH32060'
                },
                {
                  label: 'Right: HZ-5205'
                }
              ]
            }
          ]}
          products={products['BEFORE DAWN']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=36"
          tokusyu
        />
      </ProductImageContainer>
      <ProductImageContainer left={825} top={1560.86 - 135 + 40} mobileLeft={14.5} mobileTop={1693.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'RAIN SOUNDS',
              nameJa: 'レイン サウンズ'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_rain_sounds_01.jpg',
              width: 225,
              height: 420,
              mobileWidth: 192.86,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'TH32066'
                }
              ]
            }
          ]}
          products={products['RAIN SOUNDS']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="left"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=19"
        />
      </ProductImageContainer>
      <ProductImageContainer left={175} top={1789 - 135} mobileLeft={102.36} mobileTop={2153.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'FANCY GEOLOGY',
              nameJa: 'ファンシー ジオロジー'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_fancy_geology_01.jpg',
              width: 300,
              height: 420,
              mobileWidth: 257.14,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'Right: TH32065'
                },
                {
                  label: 'Left: TH32311'
                }
              ]
            }
          ]}
          products={products['FANCY GEOLOGY']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=18"
        />
      </ProductImageContainer>
      <ProductImageContainer left={175} top={2428 - 135} mobileLeft={14.5} mobileTop={2970.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'MYSTIC HAZE',
              nameJa: 'ミスティック ヘイズ'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_smoky_chic_01.jpg',
              width: 360,
              height: 330,
              mobileWidth: 345,
              mobileHeight: 300,
              popup: true,
              labels: [
                {
                  label: 'Left: TH32073'
                },
                {
                  label: 'Right: HZ-5207'
                }
              ]
            }
          ]}
          products={products['MYSTIC HAZE']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=37"
          tokusyu
        />
      </ProductImageContainer>
      <ProductImageContainer left={550} top={2428 - 135} mobileLeft={14.5} mobileTop={2630.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'SMOKY CHIC',
              nameJa: 'スモーキー シック'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_smoky_chic_02.jpg',
              width: 240,
              height: 330,
              mobileWidth: 220,
              mobileHeight: 300,
              popup: true,
              labels: [
                {
                  label: 'Left: TH32071'
                }
              ]
            }
          ]}
          products={products['SMOKY CHIC']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=21"
        />
      </ProductImageContainer>
      {
        isMobile ? <>
          <ProductImageContainer left={496} top={2828 - 135} mobileLeft={14.5} mobileTop={3374.75} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'SPLENDOR',
                  nameJa: 'スプレンダー'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/faith_splendor_01.jpg',
                  width: 300,
                  height: 420,
                  mobileWidth: 172,
                  mobileHeight: 240,
                  popup: true,
                  labels: [
                    {
                      label: 'TH32083'
                    }
                  ]
                }
              ]}
              products={products['SPLENDOR']}
              popupColumns={3}
              popupLayout="vertical"
              popupSide="left"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=22"
            />
          </ProductImageContainer>
          <ProductImageContainer left={496} top={2828 - 135} mobileLeft={144.5} mobileTop={3238.75 + 402} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'SPLENDOR',
                  nameJa: 'スプレンダー'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/faith_splendor_02.jpg',
                  width: 300,
                  height: 420,
                  mobileWidth: 215,
                  mobileHeight: 300,
                  popup: true,
                  labels: [
                    {
                      label: 'TH32078'
                    }
                  ]
                }
              ]}
              products={products['SPLENDOR']}
              popupColumns={3}
              popupLayout="vertical"
              popupSide="left"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=22"
            />
          </ProductImageContainer>
        </> : <>
          <ProductImageContainer left={535.75} top={2998.4 - 135} mobileLeft={14.5} mobileTop={3374.75} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'SPLENDOR',
                  nameJa: 'スプレンダー'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/faith_splendor_01.jpg',
                  width: 300,
                  height: 420,
                  mobileWidth: 172,
                  mobileHeight: 240,
                  popup: true,
                  labels: [
                    {
                      label: 'TH32083'
                    }
                  ]
                },
                {
                  src: '/pickup/process_100/images/photos/faith_splendor_02.jpg',
                  width: 300,
                  height: 420,
                  mobileWidth: 215,
                  mobileHeight: 300,
                  popup: false,
                  labels: [
                    {
                      label: 'TH32078'
                    }
                  ]
                }
              ]}
              products={products['SPLENDOR']}
              popupColumns={3}
              popupLayout="vertical"
              popupSide="left"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=22"
            />
          </ProductImageContainer>
        </>
      }
      <ProductImageContainer left={50} top={3419 - 135 + 40} mobileLeft={58.43} mobileTop={4040.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'SHIMMER RIVER',
              nameJa: 'シマー リバー'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_shimmer_river_01.jpg',
              width: 300,
              height: 420,
              mobileWidth: 257.14,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'TH32086'
                }
              ]
            }
          ]}
          products={products['SHIMMER RIVER']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=24"
        />
      </ProductImageContainer>
      <ProductImageContainer left={535.75} top={3669 - 135} mobileLeft={166.64} mobileTop={4500.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'TRAJECTORY',
              nameJa: 'トラジェクトリー'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_trajectory_01.jpg',
              width: 225,
              height: 420,
              mobileWidth: 192.86,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'TH32089'
                }
              ]
            }
          ]}
          products={products['TRAJECTORY']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=25"
        />
      </ProductImageContainer>
      <ProductImageContainer left={205.78} top={4299 - 135} mobileLeft={14.5} mobileTop={4960.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'FASCINATE',
              nameJa: 'ファシネイト'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_ambiente_01.jpg',
              width: 330,
              height: 240,
              mobileWidth: 280,
              mobileHeight: 200,
              popup: true,
              labels: [
                {
                  label: 'TH32096'
                }
              ]
            }
          ]}
          products={products['FASCINATE']}
          popupColumns={2}
          popupLayout="horizontal"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=26"
        />
      </ProductImageContainer>
      <ProductImageContainer left={847.72} top={4299 - 135} mobileLeft={102.36} mobileTop={5260.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'TERRESTRIAL STORY',
              nameJa: 'テレストリアル ストーリー'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_terrestrial_story_01.jpg',
              width: 300,
              height: 420,
              mobileWidth: 257.14,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'TH32098'
                }
              ]
            }
          ]}
          products={products['TERRESTRIAL STORY']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="left"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=27"
        />
      </ProductImageContainer>
      <ProductImageContainer left={85.69} top={4799 - 135} mobileLeft={14.5} mobileTop={5730.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'WAVING SHADOW',
              nameJa: 'ウェービング シャドウ'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_waving_shadow_01.jpg',
              width: 450,
              height: 240,
              mobileWidth: 300,
              mobileHeight: 180,
              popup: true,
              labels: [
                {
                  label: 'TH32104'
                }
              ]
            }
          ]}
          products={products['WAVING SHADOW']}
          popupColumns={3}
          popupLayout="horizontal"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=28"
        />
      </ProductImageContainer>
      <ProductImageContainer left={700.12} top={5079 - 135} mobileLeft={14.5} mobileTop={5980.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'OBORO',
              nameJa: 'オボロ'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_oboro_01.jpg',
              width: 450,
              height: 240,
              mobileWidth: 300,
              mobileHeight: 180,
              popup: true,
              labels: [
                {
                  label: 'TH32108'
                }
              ]
            }
          ]}
          products={products['OBORO']}
          popupColumns={2}
          popupLayout="horizontal"
          popupSide="left"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=29"
        />
      </ProductImageContainer>
      {
        isMobile ? <>
          <ProductImageContainer left={90} top={4929 - 135} mobileLeft={179.5} mobileTop={6282.75 - 20} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'IDYLL',
                  nameJa: 'アイディル'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/faith_idyll_02.jpg',
                  width: 300,
                  height: 420,
                  mobileWidth: 180,
                  mobileHeight: 240,
                  popup: true,
                  labels: [
                    {
                      label: 'TH32110'
                    }
                  ]
                }
              ]}
              products={products['IDYLL']}
              popupColumns={3}
              popupLayout="vertical"
              popupSide="right"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=30"
            />
          </ProductImageContainer>
          <ProductImageContainer left={90} top={4929 - 135} mobileLeft={91.64} mobileTop={6148.75 + 402} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'IDYLL',
                  nameJa: 'アイディル'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/faith_idyll_01.jpg',
                  width: 375,
                  height: 420,
                  mobileWidth: 267.86,
                  mobileHeight: 300,
                  popup: true,
                  labels: [
                    {
                      label: 'TH32116'
                    }
                  ]
                }
              ]}
              products={products['IDYLL']}
              popupColumns={3}
              popupLayout="vertical"
              popupSide="right"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=30"
            />
          </ProductImageContainer>
        </> : <>
          <ProductImageContainer left={90.12} top={5529 - 135} mobileLeft={179.5} mobileTop={6282.75} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'IDYLL',
                  nameJa: 'アイディル'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/faith_idyll_01.jpg',
                  width: 375,
                  height: 420,
                  mobileWidth: 267.86,
                  mobileHeight: 300,
                  popup: false,
                  labels: [
                    {
                      label: 'TH32116'
                    }
                  ]
                },
                {
                  src: '/pickup/process_100/images/photos/faith_idyll_02.jpg',
                  width: 300,
                  height: 420,
                  mobileWidth: 180,
                  mobileHeight: 240,
                  popup: true,
                  labels: [
                    {
                      label: 'TH32110'
                    }
                  ]
                }
              ]}
              products={products['IDYLL']}
              popupColumns={3}
              popupLayout="vertical"
              popupSide="right"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=30"
            />
          </ProductImageContainer>
        </>
      }
      <ProductImageContainer left={240.12} top={6148.73 - 135} mobileLeft={91.53} mobileTop={6950.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'PETRICHOR',
              nameJa: 'ペトリコール'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_petrichor_01.jpg',
              width: 225,
              height: 420,
              mobileWidth: 192.86,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'TH32117'
                }
              ]
            }
          ]}
          products={products['PETRICHOR']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=32"
        />
      </ProductImageContainer>
      <ProductImageContainer left={720} top={6338.73 - 135} mobileLeft={91.53} mobileTop={7400.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'ETERNAL MOMENT',
              nameJa: 'エターナル モーメント'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_eternal_moment_01.jpg',
              width: 300,
              height: 420,
              mobileWidth: 257.14,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'TH32122'
                }
              ]
            }
          ]}
          products={products['ETERNAL MOMENT']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="left"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=33"
        />
      </ProductImageContainer>
      <ProductImageContainer left={134.78} top={6959 - 135} mobileLeft={14.5} mobileTop={7877.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'SINCERITY',
              nameJa: 'シンシアリティー'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_sincerity_01.jpg',
              width: 330,
              height: 240,
              mobileWidth: 250,
              mobileHeight: 180,
              popup: true,
              labels: [
                {
                  label: 'TH32125'
                }
              ]
            }
          ]}
          products={products['SINCERITY']}
          popupColumns={2}
          popupLayout="horizontal"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=34"
        />
      </ProductImageContainer>
      <ProductImageContainer left={479} top={7069 - 135} mobileLeft={14.5} mobileTop={8100.75} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'THROUGH THE LEAVES',
              nameJa: 'スルー ザ リーブス'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/faith_through_the_leaves_01.jpg',
              width: 600,
              height: 420,
              mobileWidth: 345,
              mobileHeight: 234.5,
              popup: true,
              labels: [
                {
                  label: 'Left: TH32123'
                },
                {
                  label: 'Right: HZ-5213'
                }
              ]
            }
          ]}
          products={products['THROUGH THE LEAVES']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="left"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/faith22/index.html#page=37"
          tokusyu
        />
      </ProductImageContainer>
      <LiquidDiv left={0} top={7795.56 - 135} width={479} height={269.44} mobileLeft={0} mobileTop={8133.75 + 402} mobileWidth={375} mobileHeight={210} fadeIn>
        <Video src="/pickup/process_100/videos/RKMV0002.mp4" poster="/pickup/process_100/videos/RKMV0002.jpg" autoPlay loop muted playsInline/>
      </LiquidDiv>
    </Inner>
  </Outer>
}

type ReserveSectionProps = {

}
export const ReserveSection: FunctionComponent<ReserveSectionProps> = () => {
  const isMobile = useBreakpoint('50rem')
  return <Outer id="reserve">
    <Inner width={1200} height={3726} mobileWidth={375} mobileHeight={3848 + 402}>
      {
        isMobile ? <>
          <MobileSeriesHeader
            title="RéSERVE"
            years="2024-2027"
            patterns="7"
            colors="19"
            imgSrc="/pickup/process_100/images/mobile_series_header_reserve.jpg"
            logoColor="black"
            logoWithCopy2
            isNew
          />
        </> : <>
          <LiquidDiv left={50} top={172.13 - 135} fadeIn fadeInDelay="0.2s">
            <SeriesHeader
              title="RéSERVE"
              align='left'
              isNew
            />
          </LiquidDiv>
          <LiquidDiv left={154.5 + (272 / 2)} top={333.64 - 135} fadeIn fadeInDelay="0.2s">
            <HeadInner2>
              <SeriesHeader2
                years="2024-2027"
                patterns="7"
                colors="19"
                logoWithCopy2
              />
            </HeadInner2>
          </LiquidDiv>
          <ImageContainer left={600} top={135 - 135} width={600} height={500} fadeIn>
            <Image src="/pickup/process_100/images/cover_reserve.jpg" width={600} height={500} />
          </ImageContainer>
        </>
      }
      <ProductImageContainer left={166} top={554 - 135} mobileLeft={77.5} mobileTop={100 + 345} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'FLORAL MEMORY',
              nameJa: 'フローラル メモリー'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/reserve_floral_memory_01.jpg',
              width: 266,
              height: 420,
              mobileWidth: 220,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'RE55072'
                }
              ]
            }
          ]}
          products={products['FLORAL MEMORY']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/reserve24/index.html#page=23"
        />
      </ProductImageContainer>
      {
        isMobile ? <>
          <ProductImageContainer left={0} top={0} mobileLeft={14.5} mobileTop={578 + 345} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'PLEUVOIR',
                  nameJa: 'プルヴォワール'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/reserve_pleuvoir_01.jpg',
                  width: 0,
                  height: 0,
                  mobileWidth: 200,
                  mobileHeight: 150,
                  popup: true,
                  labels: [
                    {
                      label: 'RE55076'
                    }
                  ]
                }
              ]}
              products={products['PLEUVOIR']}
              popupColumns={2}
              popupLayout="horizontal"
              popupSide="left"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/reserve24/index.html#page=24"
            />
          </ProductImageContainer>
          <ProductImageContainer left={0} top={0} mobileLeft={77.5} mobileTop={775 + 345} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'PLEUVOIR',
                  nameJa: 'プルヴォワール'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/reserve_pleuvoir_02.jpg',
                  width: 0,
                  height: 0,
                  mobileWidth: 282,
                  mobileHeight: 240,
                  popup: true,
                  labels: [
                    {
                      label: 'RE55074'
                    }
                  ]
                }
              ]}
              products={products['PLEUVOIR']}
              popupColumns={2}
              popupLayout="horizontal"
              popupSide="left"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/reserve24/index.html#page=24"
            />
          </ProductImageContainer>
        </> :
        <ProductImageContainer left={600} top={1015.25 - 135} mobileLeft={0} mobileTop={0} fadeIn fadeInDelay="0.4s">
          <ProductImage
            names={[
              {
                name: 'PLEUVOIR',
                nameJa: 'プルヴォワール'
              }
            ]}
            images={[
              {
                src: '/pickup/process_100/images/photos/reserve_pleuvoir_02.jpg',
                width: 380,
                height: 300,
                mobileWidth: 200,
                mobileHeight: 150,
                popup: true,
                labels: [
                  {
                    label: 'RE55074'
                  }
                ]
              },
              {
                src: '/pickup/process_100/images/photos/reserve_pleuvoir_01.jpg',
                width: 270,
                height: 185,
                mobileWidth: 200,
                mobileHeight: 150,
                top: -228,
                left: 110,
                popup: false,
                float: true,
                labels: [
                  {
                    label: 'RE55076'
                  }
                ]
              }
            ]}
            products={products['PLEUVOIR']}
            popupColumns={2}
            popupLayout="horizontal"
            popupSide="left"
            catalogLink="https://contents.sangetsu.co.jp/digital_book/reserve24/index.html#page=24"
            topName
          />
        </ProductImageContainer>
      }
      <ProductImageContainer left={50} top={1315.25 - 135} mobileLeft={77.5} mobileTop={1128 + 345} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'ETHEREAL HARMONY',
              nameJa: 'エーセリアル ハーモニー'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/reserve_ethereal_harmony_01.jpg',
              width: 250,
              height: 420,
              mobileWidth: 202,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'RE55079'
                }
              ]
            }
          ]}
          products={products['ETHEREAL HARMONY']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/reserve24/index.html#page=25"
        />
      </ProductImageContainer>
      {
        isMobile ? <>
          <ProductImageContainer left={660} top={1495.85 - 135} mobileLeft={78} mobileTop={1612 + 345} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'POTTERY ESSENCE',
                  nameJa: 'ポッタリー エッセンス'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/reserve_pottery_essence_01.jpg',
                  width: 320,
                  height: 240,
                  mobileWidth: 265,
                  mobileHeight: 200,
                  popup: true,
                  labels: [
                    {
                      label: 'RE55082'
                    }
                  ]
                }
              ]}
              products={products['POTTERY ESSENCE']}
              popupColumns={3}
              popupLayout="horizontal"
              popupSide="left"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/reserve24/index.html#page=26"
            />
          </ProductImageContainer>
          <ProductImageContainer left={0} top={0 - 135} mobileLeft={15} mobileTop={1858 + 345} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'POTTERY ESSENCE',
                  nameJa: 'ポッタリー エッセンス'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/reserve_pottery_essence_02.jpg',
                  width: 320,
                  height: 240,
                  mobileWidth: 265,
                  mobileHeight: 200,
                  popup: true,
                  labels: [
                    {
                      label: 'RE55080'
                    }
                  ]
                }
              ]}
              products={products['POTTERY ESSENCE']}
              popupColumns={2}
              popupLayout="horizontal"
              popupSide="left"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/reserve24/index.html#page=26"
            />
          </ProductImageContainer>
        </> :
        <ProductImageContainer left={660} top={1495.85 - 135} mobileLeft={78} mobileTop={1612 + 345} fadeIn fadeInDelay="0.4s">
          <ProductImage
            names={[
              {
                name: 'POTTERY ESSENCE',
                nameJa: 'ポッタリー エッセンス'
              }
            ]}
            images={[
              {
                src: '/pickup/process_100/images/photos/reserve_pottery_essence_01.jpg',
                width: 320,
                height: 240,
                mobileWidth: 265,
                mobileHeight: 200,
                popup: false,
                labels: [
                  {
                    label: 'RE55082'
                  }
                ]
              },
              {
                src: '/pickup/process_100/images/photos/reserve_pottery_essence_02.jpg',
                width: 320,
                height: 240,
                mobileWidth: 265,
                mobileHeight: 200,
                popup: true,
                float: true,
                top: 280,
                left: 0,
                labels: [
                  {
                    label: 'RE55080'
                  }
                ]
              }
            ]}
            products={products['POTTERY ESSENCE']}
            popupColumns={2}
            popupLayout="horizontal"
            popupSide="left"
            catalogLink="https://contents.sangetsu.co.jp/digital_book/reserve24/index.html#page=26"
          />
        </ProductImageContainer>
      }
      <ProductImageContainer left={175} top={2016.85 - 135} mobileLeft={140} mobileTop={2167.5 + 345} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'LAYERED STRINGS',
              nameJa: 'レイヤード ストリングス'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/reserve_layered_strings_01.jpg',
              width: 250,
              height: 420,
              mobileWidth: 220,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'RE55084'
                }
              ]
            }
          ]}
          products={products['LAYERED STRINGS']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/reserve24/index.html#page=27"
        />
      </ProductImageContainer>
      <ProductImageContainer left={820} top={2244 - 135} mobileLeft={15} mobileTop={2637.5 + 345} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'SERENITY SLATE',
              nameJa: 'セレニティ スレート'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/reserve_serenity_slate_01.jpg',
              width: 320,
              height: 420,
              mobileWidth: 292,
              mobileHeight: 345,
              popup: true,
              labels: [
                {
                  label: 'RE55087'
                }
              ]
            }
          ]}
          products={products['SERENITY SLATE']}
          popupColumns={2}
          popupLayout="vertical"
          popupSide="left"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/reserve24/index.html#page=28"
        />
      </ProductImageContainer>
      <ProductImageContainer left={175} top={2675 - 135} mobileLeft={67.5} mobileTop={3088 + 345} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'GENTLE LOUVER',
              nameJa: 'ジェントル ルーバー'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/reserve_gentle_louver_01.jpg',
              width: 300,
              height: 420,
              mobileWidth: 240,
              mobileHeight: 350,
              popup: true,
              labels: [
                {
                  label: 'Right: RE55089'
                },
                {
                  label: 'Left: RE55081'
                }
              ]
            }
          ]}
          products={products['GENTLE LOUVER']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/reserve24/index.html#page=29"
        />
      </ProductImageContainer>
      <LiquidDiv left={651} top={3205.63 - 135} width={550} height={309.38} mobileLeft={0} mobileTop={3638 + 345} mobileWidth={375} mobileHeight={210} fadeIn>
        <Video src="/pickup/process_100/videos/RKMV0001b.mp4" poster="/pickup/process_100/videos/RKMV0001b.jpg" autoPlay loop muted playsInline/>
      </LiquidDiv>
    </Inner>
  </Outer>
}

type FineSectionProps = {

}
export const FineSection: FunctionComponent<FineSectionProps> = () => {
  const isMobile = useBreakpoint('50rem')
  return <Outer id="fine">
    <Inner width={1200} height={5050.08} mobileWidth={375} mobileHeight={5100 + 402 + 210}>
      {
        isMobile ? <>
          <MobileSeriesHeader
            title="FINE"
            years="2023-2026"
            patterns="10"
            colors="33"
            imgSrc="/pickup/process_100/images/mobile_series_header_fine.jpg"
            logoColor="white"
            logoWithCopy
          />
        </> : <>
          <LiquidDiv left={50.6} top={33} fadeIn fadeInDelay="0.2s">
            <SeriesHeader
              title="FINE"
              align='left'
            />
          </LiquidDiv>
          <LiquidDiv left={161.1 + (280 / 2)} top={183.51} fadeIn fadeInDelay="0.2s">
            <HeadInner2>
              <SeriesHeader2
                years="2023-2026"
                patterns="10"
                colors="33"
                logoWithCopy
              />
            </HeadInner2>
          </LiquidDiv>
          <ImageContainer left={600} top={0} width={600} height={500} fadeIn>
            <Image src="/pickup/process_100/images/cover_fine.jpg" width={600} height={500} />
          </ImageContainer>
        </>
      }
      {
        isMobile ? <>
          <ProductImageContainer left={166.6} top={731.08} mobileLeft={78.21} mobileTop={485} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'LUSTRE ARROW',
                  nameJa: 'ラスター アロー'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/fine_lustre_arrow_01.jpg',
                  width: 270,
                  height: 185,
                  mobileWidth: 220,
                  mobileHeight: 150,
                  popup: true,
                  labels: [
                    {
                      label: 'FE76049'
                    }
                  ]
                }
              ]}
              products={products['LUSTRE ARROW']}
              popupColumns={3}
              popupLayout="vertical"
              popupSide="right"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=33"
            />
          </ProductImageContainer>
          <ProductImageContainer left={166.6} top={731.08} mobileLeft={78} mobileTop={678} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'LUSTRE ARROW',
                  nameJa: 'ラスター アロー'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/fine_lustre_arrow_02.jpg',
                  width: 378,
                  height: 420,
                  mobileWidth: 282,
                  mobileHeight: 310,
                  popup: true,
                  labels: [
                    {
                      label: 'FE76048'
                    }
                  ]
                }
              ]}
              products={products['LUSTRE ARROW']}
              popupColumns={3}
              popupLayout="vertical"
              popupSide="right"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=33"
            />
          </ProductImageContainer>
        </> :
        <ProductImageContainer left={166.6} top={506.08 + 225 - 10} mobileLeft={0} mobileTop={0} fadeIn fadeInDelay="0.4s">
          <ProductImage
            names={[
              {
                name: 'LUSTRE ARROW',
                nameJa: 'ラスター アロー'
              }
            ]}
            images={[
              {
                src: '/pickup/process_100/images/photos/fine_lustre_arrow_01.jpg',
                width: 270,
                height: 185,
                mobileWidth: 345,
                mobileHeight: 210,
                float: true,
                top: -225 + 10,
                popup: false,
                labels: [
                  {
                    label: 'FE76049'
                  }
                ]
              },
              {
                src: '/pickup/process_100/images/photos/fine_lustre_arrow_02.jpg',
                width: 378,
                height: 420,
                mobileWidth: 0,
                mobileHeight: 0,
                popup: true,
                labels: [
                  {
                    label: 'FE76048'
                  }
                ]
              }
            ]}
            products={products['LUSTRE ARROW']}
            popupColumns={3}
            popupLayout="vertical"
            popupSide="right"
            catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=33"
          />
        </ProductImageContainer>
      }
      <ProductImageContainer left={772.2} top={941.08} mobileLeft={77} mobileTop={1732} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'ALUNISSAGE',
              nameJa: 'アルニサージュ'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/fine_alunissage_01.jpg',
              width: 255,
              height: 420,
              mobileWidth: 220,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'FE76054'
                }
              ]
            }
          ]}
          products={products['ALUNISSAGE']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="left"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=35"
        />
      </ProductImageContainer>
      {
        isMobile ? <>
          <ProductImageContainer left={50} top={1461.08} mobileLeft={15} mobileTop={1107} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'GRID GRAND',
                  nameJa: 'グリッド グランド'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/fine_grid_grand_01.jpg',
                  width: 255,
                  height: 270,
                  mobileWidth: 220,
                  mobileHeight: 230,
                  popup: true,
                  labels: [
                    {
                      label: 'FE76052'
                    }
                  ]
                }
              ]}
              products={products['GRID GRAND']}
              popupColumns={2}
              popupLayout="horizontal"
              popupSide="right"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=34"
            />
          </ProductImageContainer>
          <ProductImageContainer left={50} top={1461.08} mobileLeft={78} mobileTop={1380} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'GRID GRAND',
                  nameJa: 'グリッド グランド'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/fine_grid_grand_02.jpg',
                  width: 330,
                  height: 270,
                  mobileWidth: 282,
                  mobileHeight: 230,
                  popup: true,
                  labels: [
                    {
                      label: 'FE76051'
                    }
                  ]
                }
              ]}
              products={products['GRID GRAND']}
              popupColumns={2}
              popupLayout="horizontal"
              popupSide="right"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=34"
            />
          </ProductImageContainer>
        </> :
        <ProductImageContainer left={50} top={1461.08} mobileLeft={0} mobileTop={0} fadeIn fadeInDelay="0.4s">
          <ProductImage
            names={[
              {
                name: 'GRID GRAND',
                nameJa: 'グリッド グランド'
              }
            ]}
            images={[
              {
                src: '/pickup/process_100/images/photos/fine_grid_grand_01.jpg',
                width: 255,
                height: 270,
                mobileWidth: 0,
                mobileHeight: 0,
                popup: false,
                labels: [
                  {
                    label: 'FE76052'
                  }
                ]
              },
              {
                src: '/pickup/process_100/images/photos/fine_grid_grand_02.jpg',
                width: 330,
                height: 270,
                mobileWidth: 0,
                mobileHeight: 0,
                popup: true,
                labels: [
                  {
                    label: 'FE76051'
                  }
                ]
              }
            ]}
            products={products['GRID GRAND']}
            popupColumns={2}
            popupLayout="horizontal"
            popupSide="right"
            catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=34"
          />
        </ProductImageContainer>
      }
      {
        isMobile ? <>
          <ProductImageContainer left={550} top={1911.08} mobileLeft={15} mobileTop={2212} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'CALM VOICE',
                  nameJa: 'カーム ボイス'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/fine_calm_voice_01.jpg',
                  width: 330,
                  height: 270,
                  mobileWidth: 282,
                  mobileHeight: 230,
                  popup: true,
                  labels: [
                    {
                      label: 'FE76059'
                    }
                  ]
                }
              ]}
              products={products['CALM VOICE']}
              popupColumns={2}
              popupLayout="horizontal"
              popupSide="left"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=36"
            />
          </ProductImageContainer>
          <ProductImageContainer left={550} top={1911.08} mobileLeft={140} mobileTop={2485} fadeIn fadeInDelay="0.4s">
            <ProductImage
              names={[
                {
                  name: 'CALM VOICE',
                  nameJa: 'カーム ボイス'
                }
              ]}
              images={[
                {
                  src: '/pickup/process_100/images/photos/fine_calm_voice_02.jpg',
                  width: 330,
                  height: 270,
                  mobileWidth: 220,
                  mobileHeight: 230,
                  popup: true,
                  labels: [
                    {
                      label: 'FE76061'
                    }
                  ]
                }
              ]}
              products={products['CALM VOICE']}
              popupColumns={2}
              popupLayout="horizontal"
              popupSide="left"
              catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=36"
            />
          </ProductImageContainer>
        </> :
        <ProductImageContainer left={550} top={1911.08} mobileLeft={0} mobileTop={0} fadeIn fadeInDelay="0.4s">
          <ProductImage
            names={[
              {
                name: 'CALM VOICE',
                nameJa: 'カーム ボイス'
              }
            ]}
            images={[
              {
                src: '/pickup/process_100/images/photos/fine_calm_voice_01.jpg',
                width: 330,
                height: 270,
                mobileWidth: 0,
                mobileHeight: 0,
                popup: true,
                labels: [
                  {
                    label: 'FE76059'
                  }
                ]
              },
              {
                src: '/pickup/process_100/images/photos/fine_calm_voice_02.jpg',
                width: 255,
                height: 270,
                mobileWidth: 0,
                mobileHeight: 0,
                popup: false,
                labels: [
                  {
                    label: 'FE76061'
                  }
                ]
              }
            ]}
            products={products['CALM VOICE']}
            popupColumns={2}
            popupLayout="horizontal"
            popupSide="left"
            catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=36"
          />
        </ProductImageContainer>
      }
      <ProductImageContainer left={140.97} top={2046.08} mobileLeft={77.5} mobileTop={2835} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'TOUCH A CHORD',
              nameJa: 'タッチ ア コード'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/fine_touch_a_chord_01.jpg',
              width: 255,
              height: 420,
              mobileWidth: 220,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'FE76062'
                }
              ]
            }
          ]}
          products={products['TOUCH A CHORD']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=37"
        />
      </ProductImageContainer>
      <ProductImageContainer left={615} top={2506.08} mobileLeft={15} mobileTop={3305} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'PERSIAN DREAM',
              nameJa: 'ペルシアン ドリーム'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/fine_persian_dream_01.jpg',
              width: 360,
              height: 334,
              mobileWidth: 282,
              mobileHeight: 235,
              popup: true,
              labels: [
                {
                  label: 'FE76066'
                }
              ]
            }
          ]}
          products={products['PERSIAN DREAM']}
          popupColumns={1}
          popupLayout="horizontal"
          popupSide="left"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=38"
        />
      </ProductImageContainer>
      <ProductImageContainer left={220} top={2866.08} mobileLeft={77.5} mobileTop={3610} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'EXOTIC JACQUARD',
              nameJa: 'エキゾチック ジャカード'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/fine_exotic_jacquard_01.jpg',
              width: 360,
              height: 300,
              mobileWidth: 282,
              mobileHeight: 235,
              popup: true,
              labels: [
                {
                  label: 'FE76069'
                }
              ]
            }
          ]}
          products={products['EXOTIC JACQUARD']}
          popupColumns={1}
          popupLayout="horizontal"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=38"
        />
      </ProductImageContainer>
      <ProductImageContainer left={728} top={3201.46} mobileLeft={78.21} mobileTop={3965} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'JAGGY STRIPE',
              nameJa: 'ジャギー ストライプ'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/fine_jaggy_stripe_01.jpg',
              width: 255,
              height: 420,
              mobileWidth: 218.57,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'FE76072'
                }
              ]
            }
          ]}
          products={products['JAGGY STRIPE']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="left"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=39"
        />
      </ProductImageContainer>
      <ProductImageContainer left={50} top={3651.46} mobileLeft={15} mobileTop={4445} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'SPACIOUS TILE',
              nameJa: 'スペイシャス タイル'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/fine_spacious_tile_01.jpg',
              width: 330,
              height: 420,
              mobileWidth: 282.86,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'FE76074'
                }
              ]
            }
          ]}
          products={products['SPACIOUS TILE']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="right"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=40"
        />
      </ProductImageContainer>
      <ProductImageContainer left={728} top={3861.46} mobileLeft={111.43} mobileTop={4925} fadeIn fadeInDelay="0.4s">
        <ProductImage
          names={[
            {
              name: 'SHABBY MEMORY',
              nameJa: 'シャビー メモリー'
            }
          ]}
          images={[
            {
              src: '/pickup/process_100/images/photos/fine_shabby_memory_01.jpg',
              width: 290,
              height: 420,
              mobileWidth: 248.57,
              mobileHeight: 360,
              popup: true,
              labels: [
                {
                  label: 'FE76077'
                }
              ]
            }
          ]}
          products={products['SHABBY MEMORY']}
          popupColumns={3}
          popupLayout="vertical"
          popupSide="left"
          catalogLink="https://contents.sangetsu.co.jp/digital_book/fine23/index.html#page=41"
        />
      </ProductImageContainer>

      <LiquidDiv left={0} top={4492.21} width={600} height={337.5} mobileLeft={0} mobileTop={5485} mobileWidth={375} mobileHeight={210} fadeIn>
        <Video src="/pickup/process_100/videos/RKMV0005c.mp4" poster="/pickup/process_100/videos/RKMV0005c.jpg" autoPlay loop muted playsInline/>
      </LiquidDiv>
    </Inner>
  </Outer>
}