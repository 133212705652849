import React from 'react';
import { FunctionComponent } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useExternalCss } from '../hooks/use-external-css';

export const GlobalStyle = createGlobalStyle`
  :root {
  }
  body {
  }
`

export const RootCss: FunctionComponent = () => {
  useExternalCss('https://unpkg.com/swiper@8/swiper-bundle.min.css')
  useExternalCss('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@200;300;400&family=Plus+Jakarta+Sans:wght@200;300;400;600;700;800&display=swap')
  return <GlobalStyle />
}