import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { scrollTo } from '../utils/scroll-to'
import { Image } from './image'

const Outer = styled.a`
  display: block;
`

const Label = styled.div`
  padding: 1.5rem 1rem;
  font-size: 0.75rem;
  letter-spacing: 0.03em;
`
const TitleContainer = styled.div`
  border-top: 1px solid #717071;
`

const CoverImage = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.3rem;
  margin: 0.2rem 1rem;
`

const ImageContainer = styled.div`
  position: relative;
  width: auto;
  img {
    width: 100%;
    display: block;
    height: auto;
  }
`

const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: min(3vw, 1rem);
  font-weight: 300;
  letter-spacing: 0.03em;
  filter: drop-shadow(1px 1px 0.2rem #000);
  display: block;
`

const TitleRow = styled.div`
  display: flex;
  gap: 0.2rem;
  padding-bottom: 0.5rem;
  align-items: flex-end;
`

const Title = styled.div`
  font-weight: 700;
  font-size: min(3.5vw, 1rem);
`

const SubTitle = styled.div`

`

const Label2 = styled.div`
  padding-bottom: 0.5rem;
`

const YearsRow = styled.div`
  display: flex;
  gap: 0.2rem;
`

const Years = styled.div`
  white-space: nowrap;
`

const NewLabel = styled.div`
  padding: 0 0.4rem;
  white-space: nowrap;
  span {
    font-weight: 700;
    padding: 0 0.4rem;
  }
`

const ImageContainer2 = styled.div`

  img {
    width: 100%;
    display: block;
    height: auto;
  }
`


const Detail = styled.div`
  display: flex;
  justify-content: center;
  font-size: min(2.5vw, 1rem);
  padding: 1rem 0;
  letter-spacing: 0.125em;
`

const Patterns = styled.div`
  position: relative;
  padding: 0 1rem;
  &::after {
    position: absolute;
    content: "";
    width: 1px;
    right: 0;
    top: 15%;
    height: 80%;
    background-color: currentColor;
  }
  `

const Colors = styled.div`
  padding: 0 1rem;
`
type MobileCoverProps = {
  label?: string;
  title: string;
  subtitle?: string;
  years: string;
  isNew?: boolean;
  patterns: string;
  colors: string;
  imgSrc: string;
  imgSrc2: string;
  target: string;
}
export const MobileCover: FunctionComponent<MobileCoverProps> = ({label, title, subtitle, years, isNew, patterns, colors, imgSrc, imgSrc2, target}) => {
  return <Outer href={`#${target}`} onClick={scrollTo}>
    {
      label &&
      <Label>{label}</Label>
    }
    <TitleContainer>
      <CoverImage>
          <ImageContainer>
            <Image src={imgSrc} width={335} height={291}/>
            <TextContainer>
              <TitleRow>
                <Title>{title}</Title>
                <SubTitle>{subtitle}</SubTitle>
              </TitleRow>
              <Label2>WALLCOVERLINGS</Label2>
              <YearsRow>
                <Years>{years}</Years>
                {
                  isNew &&
                  <NewLabel>| <span>NEW</span> |</NewLabel>
                }
              </YearsRow>
            </TextContainer>
          </ImageContainer>
          <ImageContainer2>
            <Image src={imgSrc2} width={335} height={291}/>
          </ImageContainer2>
      </CoverImage>
      <Detail>
          <Patterns>{patterns} PATTERNS</Patterns>
          <Colors>{colors} COLORS</Colors>
      </Detail>
    </TitleContainer>
  </Outer>
}