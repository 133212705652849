import { MouseEvent } from 'react'

export const scrollTo = (e: MouseEvent<HTMLAnchorElement>) => {
  if(e.currentTarget.origin === location.origin && e.currentTarget.pathname === location.pathname){
    e.preventDefault()
    const targetId = e.currentTarget.hash.replace(/^#/, '')
    if(targetId){
      document.getElementById(targetId)?.scrollIntoView({behavior: 'smooth'})
    } else {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }
}