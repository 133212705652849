import classNames from 'classnames'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { useBreakpoint } from '../hooks/use-break-point'
import { useModal } from '../hooks/use-modal'
import { products } from '../products'
import { Product } from '../types/product'
import { lw } from '../utils/lw'
import { isNotNullish } from '../utils/type-check'
import { Image } from './image'
import { LiquidDiv, StaticLiquidDiv } from './liquid-div'
import { MobilePopup } from './mobile-popup'
import { LayoutType, Popup, Side } from './popup'
import { usePopup } from './popup-context-provider'

const Outer = styled.div`
  width: max-content;
  position: relative;
`

const Inner = styled.div`
  cursor: pointer;
  z-index: 2;
  position: relative;
`

const ItemContainer = styled.div`
  display: flex;
  gap: ${lw('15')};
  position: relative;
`

const Item = styled.div`
  position: relative;
`

const FloatItem = styled(LiquidDiv)``

const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
`

const ImageOuter = styled(StaticLiquidDiv)`
  overflow: hidden;
  transition: 0.3s;
  img {
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    object-fit: cover;
    @media (-webkit-max-device-pixel-ratio: 1), (max-resolution: 192dpi) { 
    }
    ${Outer}.open &{
      transform: scale(1.05,1.05);
    }
  }
`

const LabelContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.3rem;
  padding-top: 0.4375rem;
  font-size: min(${lw(12)}, 0.5625rem);
  @media (max-width: 50rem){
    font-size: 0.5625rem;
  }
`

const Label = styled.div`
  display: block;
`

const NameOuter = styled.div`
  padding-top: 0.4375rem;
  position: absolute;
  top: 100%;
  left: 0;
  &.top {
    bottom: 100%;
    top: auto;
    padding-top: 0;
    padding-bottom: 0.4375rem;
  }
`

const NameContainer = styled.div`
  display: flex;
  gap: min(${lw(16)}, 1rem);
  cursor: pointer;
`

const NameItem = styled.div`
`

const Name = styled.div`
  font-size: min(${lw(14)}, 0.75rem);
  letter-spacing: 0.06em;
  @media (max-width: 50rem){
    font-size: 0.75rem;
  }
`

const NameJa = styled.div`
  font-size: min(${lw(12)}, 0.5625rem);
  margin-top: 0.25rem;
  @media (max-width: 40rem){
    font-size: 0.5625rem;
  }
`
const PopupWrapper = styled(LiquidDiv)`
  pointer-events: none;
`
const PopupContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
  z-index: 100;
  ${Outer}:hover & {
    z-index: 101;
  }
  overflow: hidden;
  &.left {
    right: 100%;
  }
  &.right {
    left: 100%;
  }
`

const MobilePopupContainer = styled.div`
  pointer-events: none;
`

const TokusyuOuter = styled.div`
  margin-top: min(${lw(12)}, 0.65rem);
  @media (max-width: 50rem){
    margin-top: 0.5rem;
  }
`

export const TokusyuContainer = styled.div`
  display: flex;
  gap: min(${lw(8)}, 0.5rem);
  @media (max-width: 50rem){
    gap: 0.5rem;
  }
`

export const TokusyuIcon = styled.div`
  border-radius: min(${lw(4)}, 0.25rem);
  font-size: min(${lw(12)}, 0.65rem);
  padding: min(${lw(4)}, 0.25rem) min(${lw(8)}, 0.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  @media (max-width: 50rem){
    border-radius: 0.25rem;
    font-size: 0.625rem;
    padding: 0.25rem 0.5rem;
  }
`

export const TokusyuIcon1 = styled(TokusyuIcon)`
  background-color: #888;
  color: white;
`

export const TokusyuIcon2 = styled(TokusyuIcon)`
  background-color: white;
  color: #888;
`
export type ProductImage = {
  src: string,
  width: number,
  height: number,
  mobileWidth: number,
  mobileHeight: number,
  left?: number,
  right?: number,
  top?: number,
  bottom?: number
  mobileLeft?: number,
  mobileRight?: number,
  mobileTop?: number,
  mobileBottom?: number,
  float?: boolean,
  popup: boolean,
  labels: {
    label: string
  }[]
}

type ProductProps = {
  names: {
    name: string,
    nameJa: string,
  }[],
  images: ProductImage[],
  products: Product[],
  popupColumns: number
  popupLayout: LayoutType
  popupSide: Side
  catalogLink: string
  tokusyu?: boolean
  topName?: boolean
}
export const ProductImage: FunctionComponent<ProductProps> = (props) => {
  const isMobile = useBreakpoint('50rem')
  const [popupIsOpen, setPopupIsOpen] = useState(false)
  const [keep, setKeep] = useState(false)
  const {open: openModal} = useModal()
  const {open: popupOpen, close: popupClose} = usePopup()
  const usingProduct = Object.assign({}, props.images.map(image => image.labels.map(label => label.label.replace(/.* /, ''))).flat(2).map(label => props.products.find(product => product.name === label)).filter(isNotNullish)[0])
  usingProduct.name = `${usingProduct.name} `
  usingProduct.image50 = props.images[0].src
  usingProduct.image80 = props.images[0].src

  const open = async () => {
    if(isMobile){
      const scrollY = window.scrollY
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      await openModal<void>(({resolve}) => {
        return <MobilePopup
          products={[usingProduct, ...props.products]}
          open={keep}
          onClose={() => {
            resolve()
          }}
          catalogLink={props.catalogLink}
          name={props.names[0] ?? {name: '', nameJa: ''}}
          image={props.images[0]}
          tokusyu={props.tokusyu}
        />
      })
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo({top: scrollY})
    } else {
      if(keep){
        popupClose()
      } else {
        setKeep(true)
        popupOpen(() => {
          setKeep(false)
          setPopupIsOpen(false)
        })
        setPopupIsOpen(true)
      }
    }
  }

  const popupTarget = props.images.find(i => i.popup && i.float)
  const popup = <PopupContainer className={classNames(props.popupSide)}>
    <Popup
      products={props.products}
      catalogLink={props.catalogLink}
      layout={props.popupLayout}
      onClose={() => {
        popupClose()
        setKeep(false)
        setPopupIsOpen(false)
      }}
      open={popupIsOpen}
      columns={props.popupColumns}
      side={props.popupSide}
    />
  </PopupContainer>

  return <Outer
    onMouseEnter={() => {
      setPopupIsOpen(true)
    }}
    onMouseLeave={() => {
      if(!keep){
        setPopupIsOpen(false)
      }
    }}
    className={classNames({open: popupIsOpen})}
  >
    <Inner>
      <ItemContainer>
        {
          props.images.map((image, i) => {
            const children = <>
              <ImageWrapper>
                <ImageOuter width={image.width} height={image.height} mobileWidth={image.mobileWidth} mobileHeight={image.mobileHeight}>
                  <Image src={image.src} width={image.width} height={image.height} onClick={open} role="button"/>
                </ImageOuter>
              </ImageWrapper>
              <LabelContainer>
                {
                  image.labels.map((label, i) => {
                    return <Label key={i}>{label.label}</Label>
                  })
                }
              </LabelContainer>
            </>
            return image.float ?
            <FloatItem key={i}
              left={image.left}
              right={image.right}
              top={image.top}
              bottom={image.bottom}
              mobileLeft={image.mobileLeft}
              mobileRight={image.mobileRight}
              mobileTop={image.mobileTop}
              mobileBottom={image.mobileBottom}
            >
              {children}
            </FloatItem> :
            <Item key={i}>
              {children}
            </Item>
          })
        }
      </ItemContainer>
      <NameOuter className={classNames({top: props.topName})}>
        <NameContainer
          onClick={open}
          role="button"
        >
          {
            props.names.map((name, i) => {
              return <NameItem key={i}>
                <Name>{name.name}</Name>
                <NameJa>{name.nameJa}</NameJa>
              </NameItem>
            })
          }
        </NameContainer>
        {
          props.tokusyu &&
          <TokusyuOuter>
            <TokusyuContainer>
              <TokusyuIcon1>特殊掛率品</TokusyuIcon1>
              <TokusyuIcon2>受注生産品</TokusyuIcon2>
            </TokusyuContainer>
          </TokusyuOuter>
        }
      </NameOuter>
    </Inner>
    {
      isMobile ||
      popupTarget ?
      <PopupWrapper top={popupTarget?.top} left={popupTarget?.left} width={popupTarget?.width} height={popupTarget?.height}>
        {popup}
      </PopupWrapper> :
      popup
    }
  </Outer>
}