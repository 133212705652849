import * as React from "react";
import type { SVGProps } from "react";
const SvgMenuCloseButton = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23.334}
    height={23.334}
    viewBox="0 0 23.334 23.334"
    {...props}
  >
    <g
      fill="none"
      stroke="#555"
      strokeMiterlimit={10}
      strokeWidth={3}
      data-name="Group 2077"
    >
      <path d="m1.061 1.061 21.213 21.213" data-name="Line 92" />
      <path d="M1.061 22.274 22.274 1.061" data-name="Line 93" />
    </g>
  </svg>
);
export default SvgMenuCloseButton;
