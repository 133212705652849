export const products = {
  "SLOW AND MELLOW": [
    {
      "image80": "/pickup/process_100/images/tip/TH32051_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32051_50.jpg",
      "name": "TH32051",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32051/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32052_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32052_50.jpg",
      "name": "TH32052",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32052/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32053_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32053_50.jpg",
      "name": "TH32053",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32053/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32054_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32054_50.jpg",
      "name": "TH32054",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32054/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32055_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32055_50.jpg",
      "name": "TH32055",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32055/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32056_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32056_50.jpg",
      "name": "TH32056",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32056/"
    }
  ],
  "SILKY VOICE": [
    {
      "image80": "/pickup/process_100/images/tip/TH32057_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32057_50.jpg",
      "name": "TH32057",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32057/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32058_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32058_50.jpg",
      "name": "TH32058",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32058/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32059_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32059_50.jpg",
      "name": "TH32059",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32059/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32060_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32060_50.jpg",
      "name": "TH32060",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32060/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32061_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32061_50.jpg",
      "name": "TH32061",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32061/"
    }
  ],
  "FANCY GEOLOGY": [
    {
      "image80": "/pickup/process_100/images/tip/TH32062_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32062_50.jpg",
      "name": "TH32062",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32062/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32063_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32063_50.jpg",
      "name": "TH32063",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32063/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32064_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32064_50.jpg",
      "name": "TH32064",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32064/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32065_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32065_50.jpg",
      "name": "TH32065",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32065/"
    }
  ],
  "RAIN SOUNDS": [
    {
      "image80": "/pickup/process_100/images/tip/TH32066_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32066_50.jpg",
      "name": "TH32066",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32066/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32067_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32067_50.jpg",
      "name": "TH32067",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32067/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32068_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32068_50.jpg",
      "name": "TH32068",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32068/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32069_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32069_50.jpg",
      "name": "TH32069",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32069/"
    }
  ],
  "SMOKY CHIC": [
    {
      "image80": "/pickup/process_100/images/tip/TH32071_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32071_50.jpg",
      "name": "TH32071",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32071/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32072_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32072_50.jpg",
      "name": "TH32072",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32072/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32073_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32073_50.jpg",
      "name": "TH32073",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32073/"
    }
  ],
  "SPLENDOR": [
    {
      "image80": "/pickup/process_100/images/tip/TH32074_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32074_50.jpg",
      "name": "TH32074",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32074/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32075_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32075_50.jpg",
      "name": "TH32075",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32075/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32076_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32076_50.jpg",
      "name": "TH32076",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32076/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32077_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32077_50.jpg",
      "name": "TH32077",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32077/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32078_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32078_50.jpg",
      "name": "TH32078",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32078/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32079_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32079_50.jpg",
      "name": "TH32079",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32079/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32080_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32080_50.jpg",
      "name": "TH32080",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32080/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32081_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32081_50.jpg",
      "name": "TH32081",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32081/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32082_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32082_50.jpg",
      "name": "TH32082",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32082/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32083_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32083_50.jpg",
      "name": "TH32083",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32083/"
    }
  ],
  "SHIMMER RIVER": [
    {
      "image80": "/pickup/process_100/images/tip/TH32084_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32084_50.jpg",
      "name": "TH32084",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32084/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32085_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32085_50.jpg",
      "name": "TH32085",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32085/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32086_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32086_50.jpg",
      "name": "TH32086",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32086/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32087_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32087_50.jpg",
      "name": "TH32087",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32087/"
    }
  ],
  "TRAJECTORY": [
    {
      "image80": "/pickup/process_100/images/tip/TH32088_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32088_50.jpg",
      "name": "TH32088",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32088/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32089_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32089_50.jpg",
      "name": "TH32089",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32089/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32090_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32090_50.jpg",
      "name": "TH32090",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32090/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32091_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32091_50.jpg",
      "name": "TH32091",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32091/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32092_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32092_50.jpg",
      "name": "TH32092",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32092/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32093_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32093_50.jpg",
      "name": "TH32093",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32093/"
    }
  ],
  "FASCINATE": [
    {
      "image80": "/pickup/process_100/images/tip/TH32094_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32094_50.jpg",
      "name": "TH32094",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32094/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32095_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32095_50.jpg",
      "name": "TH32095",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32095/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32096_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32096_50.jpg",
      "name": "TH32096",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32096/"
    }
  ],
  "TERRESTRIAL STORY": [
    {
      "image80": "/pickup/process_100/images/tip/TH32097_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32097_50.jpg",
      "name": "TH32097",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32097/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32098_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32098_50.jpg",
      "name": "TH32098",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32098/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32099_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32099_50.jpg",
      "name": "TH32099",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32099/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32100_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32100_50.jpg",
      "name": "TH32100",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32100/"
    }
  ],
  "WAVING SHADOW": [
    {
      "image80": "/pickup/process_100/images/tip/TH32101_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32101_50.jpg",
      "name": "TH32101",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32101/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32102_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32102_50.jpg",
      "name": "TH32102",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32102/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32103_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32103_50.jpg",
      "name": "TH32103",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32103/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32104_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32104_50.jpg",
      "name": "TH32104",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32104/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32105_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32105_50.jpg",
      "name": "TH32105",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32105/"
    }
  ],
  "OBORO": [
    {
      "image80": "/pickup/process_100/images/tip/TH32106_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32106_50.jpg",
      "name": "TH32106",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32106/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32107_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32107_50.jpg",
      "name": "TH32107",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32107/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32108_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32108_50.jpg",
      "name": "TH32108",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32108/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32109_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32109_50.jpg",
      "name": "TH32109",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32109/"
    }
  ],
  "IDYLL": [
    {
      "image80": "/pickup/process_100/images/tip/TH32110_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32110_50.jpg",
      "name": "TH32110",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32110/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32111_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32111_50.jpg",
      "name": "TH32111",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32111/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32112_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32112_50.jpg",
      "name": "TH32112",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32112/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32113_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32113_50.jpg",
      "name": "TH32113",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32113/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32114_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32114_50.jpg",
      "name": "TH32114",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32114/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32115_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32115_50.jpg",
      "name": "TH32115",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32115/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32116_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32116_50.jpg",
      "name": "TH32116",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32116/"
    }
  ],
  "PETRICHOR": [
    {
      "image80": "/pickup/process_100/images/tip/TH32117_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32117_50.jpg",
      "name": "TH32117",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32117/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32118_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32118_50.jpg",
      "name": "TH32118",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32118/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32119_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32119_50.jpg",
      "name": "TH32119",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32119/"
    }
  ],
  "ETERNAL MOMENT": [
    {
      "image80": "/pickup/process_100/images/tip/TH32120_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32120_50.jpg",
      "name": "TH32120",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32120/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32121_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32121_50.jpg",
      "name": "TH32121",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32121/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32122_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32122_50.jpg",
      "name": "TH32122",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32122/"
    }
  ],
  "SINCERITY": [
    {
      "image80": "/pickup/process_100/images/tip/TH32123_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32123_50.jpg",
      "name": "TH32123",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32123/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32124_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32124_50.jpg",
      "name": "TH32124",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32124/"
    },
    {
      "image80": "/pickup/process_100/images/tip/TH32125_80.jpg",
      "image50": "/pickup/process_100/images/tip/TH32125_50.jpg",
      "name": "TH32125",
      "link": "https://www.sangetsu.co.jp/product/detail/TH32125/"
    }
  ],
  "BEFORE DAWN": [
    {
      "image80": "/pickup/process_100/images/tip/HZ-5201_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5201_50.jpg",
      "name": "HZ-5201",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5201.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5202_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5202_50.jpg",
      "name": "HZ-5202",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5202.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5203_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5203_50.jpg",
      "name": "HZ-5203",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5203.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5204_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5204_50.jpg",
      "name": "HZ-5204",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5204.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5205_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5205_50.jpg",
      "name": "HZ-5205",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5205.html"
    }
  ],
  "MYSTIC HAZE": [
    {
      "image80": "/pickup/process_100/images/tip/HZ-5206_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5206_50.jpg",
      "name": "HZ-5206",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5206.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5207_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5207_50.jpg",
      "name": "HZ-5207",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5207.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5208_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5208_50.jpg",
      "name": "HZ-5208",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5208.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5209_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5209_50.jpg",
      "name": "HZ-5209",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5209.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5210_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5210_50.jpg",
      "name": "HZ-5210",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5210.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5211_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5211_50.jpg",
      "name": "HZ-5211",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5211.html"
    }
  ],
  "THROUGH THE LEAVES": [
    {
      "image80": "/pickup/process_100/images/tip/HZ-5212_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5212_50.jpg",
      "name": "HZ-5212",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5212.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5213_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5213_50.jpg",
      "name": "HZ-5213",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5213.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5214_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5214_50.jpg",
      "name": "HZ-5214",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5214.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5215_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5215_50.jpg",
      "name": "HZ-5215",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5215.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5216_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5216_50.jpg",
      "name": "HZ-5216",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5216.html"
    },
    {
      "image80": "/pickup/process_100/images/tip/HZ-5217_80.jpg",
      "image50": "/pickup/process_100/images/tip/HZ-5217_50.jpg",
      "name": "HZ-5217",
      "link": "https://www.sangetsu.co.jp/pickup/digital_print/wallpaper/select_order/process_100/5217.html"
    }
  ],
  "REFLECTION IN WATER": [
    {
      "image80": "/pickup/process_100/images/tip/RE53001_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53001_50.jpg",
      "name": "RE53001",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53001/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53002_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53002_50.jpg",
      "name": "RE53002",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53002/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53003_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53003_50.jpg",
      "name": "RE53003",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53003/"
    }
  ],
  "MIXED WEAVE": [
    {
      "image80": "/pickup/process_100/images/tip/RE53008_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53008_50.jpg",
      "name": "RE53008",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53008/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53009_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53009_50.jpg",
      "name": "RE53009",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53009/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53010_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53010_50.jpg",
      "name": "RE53010",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53010/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53011_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53011_50.jpg",
      "name": "RE53011",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53011/"
    }
  ],
  "NOBLE GEOMETRY": [
    {
      "image80": "/pickup/process_100/images/tip/RE53015_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53015_50.jpg",
      "name": "RE53015",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53015/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53016_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53016_50.jpg",
      "name": "RE53016",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53016/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53017_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53017_50.jpg",
      "name": "RE53017",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53017/"
    }
  ],
  "POWDERY STUCCO": [
    {
      "image80": "/pickup/process_100/images/tip/RE53018_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53018_50.jpg",
      "name": "RE53018",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53018/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53019_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53019_50.jpg",
      "name": "RE53019",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53019/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53020_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53020_50.jpg",
      "name": "RE53020",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53020/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53021_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53021_50.jpg",
      "name": "RE53021",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53021/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53022_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53022_50.jpg",
      "name": "RE53022",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53022/"
    }
  ],
  "STORATUM": [
    {
      "image80": "/pickup/process_100/images/tip/RE53023_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53023_50.jpg",
      "name": "RE53023",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53023/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE53024_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE53024_50.jpg",
      "name": "RE53024",
      "link": "https://www.sangetsu.co.jp/product/detail/RE53024/"
    }
  ],
	"LUSTRE ARROW": [
		{
			"image80": "/pickup/process_100/images/tip/FE76046_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76046_50.jpg",
			"name": "FE76046",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76046/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76047_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76047_50.jpg",
			"name": "FE76047",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76047/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76048_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76048_50.jpg",
			"name": "FE76048",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76048/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76049_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76049_50.jpg",
			"name": "FE76049",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76049/"
		}
	],
	"GRID GRAND": [
		{
			"image80": "/pickup/process_100/images/tip/FE76050_50.jpg",
			"image50": "/pickup/process_100/images/tip/FE76050_50.jpg",
			"name": "FE76050",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76050/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76051_50.jpg",
			"image50": "/pickup/process_100/images/tip/FE76051_50.jpg",
			"name": "FE76051",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76051/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76052_50.jpg",
			"image50": "/pickup/process_100/images/tip/FE76052_50.jpg",
			"name": "FE76052",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76052/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76053_50.jpg",
			"image50": "/pickup/process_100/images/tip/FE76053_50.jpg",
			"name": "FE76053",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76053/"
		}
	],
	"ALUNISSAGE": [
		{
			"image80": "/pickup/process_100/images/tip/FE76054_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76054_50.jpg",
			"name": "FE76054",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76054/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76055_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76055_50.jpg",
			"name": "FE76055",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76055/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76056_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76056_50.jpg",
			"name": "FE76056",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76056/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76057_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76057_50.jpg",
			"name": "FE76057",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76057/"
		}
	],
	"CALM VOICE": [
		{
			"image80": "/pickup/process_100/images/tip/FE76058_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76058_50.jpg",
			"name": "FE76058",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76058/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76059_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76059_50.jpg",
			"name": "FE76059",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76059/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76060_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76060_50.jpg",
			"name": "FE76060",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76060/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76061_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76061_50.jpg",
			"name": "FE76061",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76061/"
		}
	],
	"TOUCH A CHORD": [
		{
			"image80": "/pickup/process_100/images/tip/FE76062_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76062_50.jpg",
			"name": "FE76062",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76062/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76063_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76063_50.jpg",
			"name": "FE76063",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76063/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76064_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76064_50.jpg",
			"name": "FE76064",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76064/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76065_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76065_50.jpg",
			"name": "FE76065",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76065/"
		}
	],
	"PERSIAN DREAM": [
		{
			"image80": "/pickup/process_100/images/tip/FE76066_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76066_50.jpg",
			"name": "FE76066",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76066/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76067_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76067_50.jpg",
			"name": "FE76067",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76067/"
		}
	],
	"EXOTIC JACQUARD": [
		{
			"image80": "/pickup/process_100/images/tip/FE76068_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76068_50.jpg",
			"name": "FE76068",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76068/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76069_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76069_50.jpg",
			"name": "FE76069",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76069/"
		}
	],
	"JAGGY STRIPE": [
		{
			"image80": "/pickup/process_100/images/tip/FE76070_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76070_50.jpg",
			"name": "FE76070",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76070/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76071_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76071_50.jpg",
			"name": "FE76071",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76071/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76072_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76072_50.jpg",
			"name": "FE76072",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76072/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76073_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76073_50.jpg",
			"name": "FE76073",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76073/"
		}
	],
	"SPACIOUS TILE": [
		{
			"image80": "/pickup/process_100/images/tip/FE76074_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76074_50.jpg",
			"name": "FE76074",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76074/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76075_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76075_50.jpg",
			"name": "FE76075",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76075/"
		}
	],
	"SHABBY MEMORY": [
		{
			"image80": "/pickup/process_100/images/tip/FE76076_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76076_50.jpg",
			"name": "FE76076",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76076/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76077_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76077_50.jpg",
			"name": "FE76077",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76077/"
		},
		{
			"image80": "/pickup/process_100/images/tip/FE76078_80.jpg",
			"image50": "/pickup/process_100/images/tip/FE76078_50.jpg",
			"name": "FE76078",
			"link": "https://www.sangetsu.co.jp/product/detail/FE76078/"
		}
	],
  "FLORAL MEMORY": [
    {
      "image80": "/pickup/process_100/images/tip/RE55071_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55071_50.jpg",
      "name": "RE55071",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55071/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55072_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55072_50.jpg",
      "name": "RE55072",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55072/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55073_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55073_50.jpg",
      "name": "RE55073",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55073/"
    },
  ],
  "PLEUVOIR": [
    {
      "image80": "/pickup/process_100/images/tip/RE55074_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55074_50.jpg",
      "name": "RE55074",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55074/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55075_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55075_50.jpg",
      "name": "RE55075",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55075/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55076_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55076_50.jpg",
      "name": "RE55076",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55076/"
    },
  ],
  "ETHEREAL HARMONY": [
    {
      "image80": "/pickup/process_100/images/tip/RE55077_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55077_50.jpg",
      "name": "RE55077",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55077/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55078_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55078_50.jpg",
      "name": "RE55078",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55078/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55079_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55079_50.jpg",
      "name": "RE55079",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55079/"
    },
  ],
  "POTTERY ESSENCE": [
    {
      "image80": "/pickup/process_100/images/tip/RE55080_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55080_50.jpg",
      "name": "RE55080",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55080/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55081_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55081_50.jpg",
      "name": "RE55081",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55081/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55082_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55082_50.jpg",
      "name": "RE55082",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55082/"
    },
  ],
  "LAYERED STRINGS": [
    {
      "image80": "/pickup/process_100/images/tip/RE55083_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55083_50.jpg",
      "name": "RE55083",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55083/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55084_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55084_50.jpg",
      "name": "RE55084",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55084/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55085_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55085_50.jpg",
      "name": "RE55085",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55085/"
    },
  ],
  "SERENITY SLATE": [
    {
      "image80": "/pickup/process_100/images/tip/RE55086_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55086_50.jpg",
      "name": "RE55086",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55086/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55087_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55087_50.jpg",
      "name": "RE55087",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55087/"
    },
  ],
  "GENTLE LOUVER": [
    {
      "image80": "/pickup/process_100/images/tip/RE55088_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55088_50.jpg",
      "name": "RE55088",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55088/"
    },
    {
      "image80": "/pickup/process_100/images/tip/RE55089_80.jpg",
      "image50": "/pickup/process_100/images/tip/RE55089_50.jpg",
      "name": "RE55089",
      "link": "https://www.sangetsu.co.jp/product/detail/RE55089/"
    },
  ],
}