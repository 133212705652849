import React, { useEffect } from 'react';
import { FunctionComponent } from 'react';
import styled, { StyleSheetManager, createGlobalStyle } from 'styled-components';
import { Contents } from './components/contents';
import { Header } from './components/header';
import { MobileMenuContextProvider } from './components/mobile-menu-context-provider';
import { PopupContextProvider } from './components/popup-context-provider';
import { RootCss } from './components/root-css';
import { ModalProvider } from './hooks/use-modal';


const Outer = styled.div`
  line-height: 1;
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #231815;
  background-color: #C8C6C3;
  overflow: hidden;
  padding-bottom: 4rem;
`

export const ResetCss = createGlobalStyle`
  :where(${Outer}) :is(html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video) {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  :where(${Outer}) :is(article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section) {
    display: block;
  }
  :where(${Outer}) :is(body) {
    line-height: 1;
  }
  :where(${Outer}) :is(ol, ul) {
    list-style: none;
  }
  :where(${Outer}) :is(blockquote, q) {
    quotes: none;
  }
  :where(${Outer}) :is(blockquote, q):before,
  :where(${Outer}) :is(blockquote, q):after {
    content: '';
    content: none;
  }
  :where(${Outer}) :is(table) {
    border-collapse: collapse;
    border-spacing: 0;
  }
  :where(${Outer}) :is(a),
  :where(${Outer}) :is(a):hover {
    color: inherit;
    text-decoration: inherit;
  }
  :where(${Outer}) *,
  :where(${Outer}) *::before,
  :where(${Outer}) *::after {
    box-sizing: initial;
  }

`

const HeaderContainer = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  top: 80px;
  z-index: 10000;
  @media (max-width: 1024px){
    top: 68px;
  }
  @media (max-width: 768px){
    top: 60px;
  }
`


export const App: FunctionComponent = () => {
  useEffect(() => {
    document.body.classList.add('loaded')
  }, [])
  return <ModalProvider>
    <MobileMenuContextProvider>
      <PopupContextProvider>
        <StyleSheetManager enableVendorPrefixes>
          <Outer>
            <ResetCss />
            <RootCss />
            <HeaderContainer>
              <Header />
            </HeaderContainer>
            <Contents />
          </Outer>
        </StyleSheetManager>
      </PopupContextProvider>
    </MobileMenuContextProvider>
  </ModalProvider>
}