import classNames from 'classnames'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import SvgCloseButton from '../generated/svg/close-button'
import { Product } from '../types/product'
import { lw } from '../utils/lw'
import { Image } from './image'
import { ProductImage, TokusyuContainer, TokusyuIcon, TokusyuIcon1, TokusyuIcon2 } from './product-image'
import { ProductList } from './product-list'
import { Swiper as SwiperClass } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useBreakpoint } from '../hooks/use-break-point'
import SvgLeftArrow from '../generated/svg/left-arrow'
import SvgRightArrow from '../generated/svg/right-arrow'

const Outer = styled.div`
  background-color: #C7C5C2;
  transition: opacity 0.4s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ProductItem = styled.div`
  flex: 0 1 100vw;
  min-height: 0;
  max-height: min(100vw, calc(100vh - 20rem));
  overflow: hidden;
  position: relative;
  .swiper {
    height: 100%;
  }
  .swiper-wrapper {
    transition-timing-function: cubic-bezier(0.5, 0.5, 0, 1);
  }
`

const SlideControlContainer = styled.div``

const SlideControlButton = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  height: 100%;
  z-index: 300;
  color: #231815;
  filter: drop-shadow(0 0.125rem 0.125rem #fffc) drop-shadow(0 -0.125rem 0.125rem #fffc);
  cursor: pointer;
  svg {
    height: 1.5rem;
    width: auto;
    display: block;
    path {
      stroke-width: 0.4px;
    }

  }
`

const LeftSlideControlButton = styled(SlideControlButton)`
  left: 0;
`

const RightSlideControlButton = styled(SlideControlButton)`
  right: 0;
`


const PreviewOuter = styled.div`
  background-color: #fff;
  img {
    display: block;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    background-color: #fff;
    max-height: min(100vw, calc(100vh - 20rem));
  }
`

const ProductNameContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0 1rem;
  margin-top: 0.5rem;
`

const ProductName = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.06em;
  padding-right: 0.5rem;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 1px;
    right: 0;
    top: 0;
    height: 100%;
    background-color: currentColor;
  }
`

const ProductNameJa = styled.div`
  font-size: 0.6rem;
  padding-left: 0.5rem;
  margin-right: 1rem;
`

const TokusyuOuter = styled.div`
  align-self: center;
  .icon {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
`

const ProductListContainer = styled.div`
  padding: 0.6rem 1rem 0;
  margin-bottom: -0.25rem;
  flex: 1 1 0;
  min-height: 0;
`

const CatalogLinkContainer = styled.a`
  display: block;
  background-color: #A7A7A7;
  padding: 0.7rem 0 0.7rem;
  text-align: center;
  color: white;
  &:hover {
    text-decoration: underline;
  }
`

const CatalogLink = styled.div`
  font-size: 0.875rem;
  letter-spacing: 0.05em;
`

const CloseButton = styled.div`
  position: absolute;
  top: 3vw;
  right: 3vw;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  box-shadow: 0 0 5px rgba(0,0,0,.2);
  border-radius: 22px;
  z-index: 400;
  cursor: pointer;
  svg {
    width: 18px;
    height: auto;
    display: block;
    path {
      stroke-width: 3px;
      stroke: #aaa;
    }
  }
`

const PreventScroll = createGlobalStyle`
`

type MobilePopupProps = {
  open: boolean
  products: Product[]
  catalogLink: string
  onClose: () => void
  name: {
    name: string,
    nameJa: string,
  },
  image: ProductImage
  tokusyu?: boolean
}
export const MobilePopup: FunctionComponent<MobilePopupProps> = ({open, products, catalogLink, onClose, name, image, tokusyu}) => {
  const [selected, setSelected] = useState<string>(products[0].name)
  const [swiper, setSwiper] = useState<SwiperClass>()
  const isMobile = useBreakpoint('30rem')
  const scrollOuterRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if(scrollOuterRef.current){
      scrollOuterRef.current.scrollTo({top: 0})
    }
  }, [open])

  useEffect(() => {
    if(swiper){
      swiper.on('slideChange', () => {
        setSelected(products[swiper.realIndex].name)
      })
    }
  }, [swiper])

  return <Outer className={classNames({open})}>
    <PreventScroll />
    <ProductItem>
      <Swiper
        onSwiper={swiper => setSwiper(swiper)}
        loop
      >
        {
          products.map(product => {
            return <SwiperSlide key={product.name}>
              <PreviewOuter>
                <Image src={product.image80} />
              </PreviewOuter>
            </SwiperSlide>
          })
        }
      </Swiper>
      <SlideControlContainer>
        <LeftSlideControlButton onClick={() => {
          if(swiper){
            swiper.slidePrev()
          }
        }}>
          <SvgLeftArrow />
        </LeftSlideControlButton>
        <RightSlideControlButton onClick={() => {
          if(swiper){
            swiper.slideNext()
          }
        }}>
          <SvgRightArrow />
        </RightSlideControlButton>
      </SlideControlContainer>
    </ProductItem>
    <ProductNameContainer>
      <ProductName>{name.name}</ProductName>
      <ProductNameJa>{name.nameJa}</ProductNameJa>
      {
        tokusyu &&
        <TokusyuOuter>
          <TokusyuContainer>
            <TokusyuIcon1 className="icon">特殊掛率品</TokusyuIcon1>
            <TokusyuIcon2 className="icon">受注生産品</TokusyuIcon2>
          </TokusyuContainer>
        </TokusyuOuter>
      }
    </ProductNameContainer>
    <ProductListContainer>
      <ProductList
        columns={isMobile ? 4 : 6}
        products={products}
        onSelect={selected => {
          setSelected(selected)
          if(swiper){
            swiper.slideTo(products.findIndex(p => p.name === selected))
          }
        }}
        onThumbnailClick={selected => {
          setSelected(selected)
          if(swiper){
            swiper.slideTo(products.findIndex(p => p.name === selected))
          }
        }}
        linkTarget="label"
        selected={selected}
        scrollOuterRef={scrollOuterRef}
      />
    </ProductListContainer>
    <CatalogLinkContainer href={catalogLink} target="_blank">
      <CatalogLink>デジタルカタログを見る</CatalogLink>
    </CatalogLinkContainer>
    <CloseButton onClick={() => onClose()}>
      <SvgCloseButton />
    </CloseButton>
  </Outer>
}