import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import SvgLogo from '../generated/svg/logo'
import { lw } from '../utils/lw'
import { Concept } from './concept'
import { Cover } from './cover'
import { Image } from './image'
import { LiquidDiv, RelativeLiquidDiv } from './liquid-div'

const Outer = styled(RelativeLiquidDiv)``

const ConceptInner = styled.div`
  transform: translateX(-50%);
`

const MainContainer = styled(LiquidDiv)``

const MainImageContainer = styled(LiquidDiv)`
  position: relative;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const LogoContainer = styled.div`
  top: ${lw(218)};
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  position: absolute;
  svg {
    display: block;
    width: ${lw(165)};
    height: auto;
  }
`

const MainTextContainer = styled(LiquidDiv)``

const MainText1 = styled.div`
  font-size: 0.625rem;
  line-height: 2.1;
`

const VideoContainer = styled(LiquidDiv)``

const Video = styled.video`
  width: 100%;
  height: auto;
  display: block;
`

const CoverContainer = styled(LiquidDiv)``

const Label = styled(LiquidDiv)`
  font-size: 0.75rem;
`

type TopProps = {

}
export const Top: FunctionComponent<TopProps> = () => {
  return <Outer width={1200} height={2784}>
    <LiquidDiv left={139.5 + (330 / 2)} top={199.49} fadeIn fadeInDelay="0.2s">
      <ConceptInner>
        <Concept />
      </ConceptInner>
    </LiquidDiv>
    <MainImageContainer left={600} top={0} width={600} height={715} fadeIn>
      <Swiper
        modules={[
          EffectFade,
          Autoplay
        ]}
        effect="fade"
        autoplay={{
          delay: 3000,
        }}
        speed={2000}
      >
        <SwiperSlide>
          <Image src="/pickup/process_100/images/top_01.jpg" width="600" height="715"/>
          <LogoContainer>
            <SvgLogo />
          </LogoContainer>
        </SwiperSlide>
        <SwiperSlide>
          <Image src="/pickup/process_100/images/top_02.jpg" width="600" height="715"/>
          <LogoContainer style={{color: 'white'}}>
            <SvgLogo />
          </LogoContainer>
        </SwiperSlide>
        <SwiperSlide>
          <Image src="/pickup/process_100/images/top_03.jpg" width="600" height="715"/>
          <LogoContainer>
            <SvgLogo />
          </LogoContainer>
        </SwiperSlide>
        <SwiperSlide>
          <Image src="/pickup/process_100/images/top_04.jpg" width="600" height="715"/>
          <LogoContainer>
            <SvgLogo />
          </LogoContainer>
        </SwiperSlide>
        <SwiperSlide>
          <Image src="/pickup/process_100/images/top_05.jpg" width="600" height="715"/>
          <LogoContainer>
            <SvgLogo />
          </LogoContainer>
        </SwiperSlide>
      </Swiper>

    </MainImageContainer>
    <MainTextContainer left={600} top={785 - 10} width={548.62} fadeIn fadeInDelay="0.2s">
      <MainText1>
        Daybreak. Wind. Imagination. Brilliance. Bounce. Earth. Breath. Feeling. Space. Passion. Encounter. World. Embrace. Mist. Reflection. Sound. Prayer. Coincidence. Scent. Sensation. Senses. Movement. Forest. Song. Self. Overlap. Touch. Cloud. View. Summer rain. Gaze. Sky. Devotion. Season. Rainfall. Color. Wave. Purification. Humidity. Polish. Dance. Walk. Birth. Lucidity. Spirit. Look. Sleep. Stone. Preparation. Memory. Quiet. Depth. Music. Interest. Flavor. Grace. Freedom. Journey. Warmth. Smile. Moon. Whisper. Life. Playfulness. Crystal. Delicious. Fleeting. Sacred. Clear skies. Tale. Attention. Time. Friend. Bittersweet. Culture. Completion. Expression. Gift. Choice. Light. Snowy. Beloved. Liberation. Essence. Calm. Shadow. Nobility. Surge. Sun. Charm. Water. Transparent. Landscape. Return. Remembrance. Linger. Reverberation. Flow. Sea. Fertile.<br />
        The twinkle of the stars that moves "me"
      </MainText1>
    </MainTextContainer>
    <Label left={50} top={1094} fadeIn>
      Publication Catalogue :
    </Label>
    <CoverContainer left={50} top={1268} width={360} fadeIn fadeInDelay="0.2s">
      <Cover
        title="RéSERVE"
        target="reserve"
        subtitle=""
        years="2024-2027"
        isNew
        image={{
          src: '/pickup/process_100/images/cover_reserve_01.jpg',
          width: 360,
          height: 300
        }}
        hoverImage={{
          src: '/pickup/process_100/images/cover_reserve_02.png',
          width: 360,
          height: 300
        }}
      />
    </CoverContainer>
    <CoverContainer left={241.5} top={1748} width={360} fadeIn fadeInDelay="0.2s">
      <Cover
        title="FINE"
        target="fine"
        subtitle=""
        years="2023-2026"
        image={{
          src: '/pickup/process_100/images/cover_fine_01.jpg',
          width: 360,
          height: 300
        }}
        hoverImage={{
          src: '/pickup/process_100/images/cover_fine_02.png',
          width: 360,
          height: 300
        }}
      />
    </CoverContainer>
    <CoverContainer left={790} top={1349} width={293} fadeIn fadeInDelay="0.2s">
      <Cover
        title="FAITH"
        target="faith"
        subtitle="CONTRACT"
        years="2022-2025"
        image={{
          src: '/pickup/process_100/images/cover_faith_01.jpg',
          width: 240,
          height: 450
        }}
        hoverImage={{
          src: '/pickup/process_100/images/cover_faith_02.png',
          width: 240,
          height: 450
        }}
      />
    </CoverContainer>
    <VideoContainer left={0} top={546} width={300} fadeIn>
      <Video src="/pickup/process_100/videos/RKMV0004f.mp4" poster="/pickup/process_100/videos/RKMV0004f.jpg" autoPlay loop muted playsInline/>
    </VideoContainer>
    <VideoContainer left={50} top={2276.19} width={548.5} fadeIn>
      <Video src="/pickup/process_100/videos/RKMV0003.mp4" poster="/pickup/process_100/videos/RKMV0003.jpg" autoPlay loop muted playsInline/>
    </VideoContainer>
  </Outer>
}