import classNames from 'classnames'
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import SvgCloseButton from '../generated/svg/close-button'
import { Product } from '../types/product'
import { lw } from '../utils/lw'
import { switchFn } from '../utils/switch-fn'
import { Image } from './image'
import { ProductList } from './product-list'
import { SwitchWrap } from './switchWrap'

const Outer = styled.div`
  background-color: #fffa;
  backdrop-filter: blur(1rem) saturate(2);
  display: flex;
  gap: ${lw(18)};
  max-height: 100%;
  height: 100%;
  opacity: 0;
  transition: transform 0.4s cubic-bezier(0.25, 0.25, 0, 1), opacity 0.2s;
  pointer-events: none;
  &.left {
    transform-origin: 100% 0;
    transform: translateX(${lw(200)});
  }
  &.right {
    transform-origin: 0 0;
    transform: translateX(${lw(-200)});
  }
  &.open {
    opacity: 1;
    transform: none;
    pointer-events: auto;
  }
`


const Inner = styled.div`
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
`

const ListOuter = styled.div`
  display: flex;
  gap: ${lw(18)};
  height: 0;
  flex: 1 1 0;
  .horizontal & {
    flex-direction: row;
  }
  .vertical & {
    flex-direction: column;
  }
`

const PreviewContainer = styled.div`
  width: ${lw('200')};
  display: block;
  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    .horizontal & {
    }
  }
`


const ProductListContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  .horizontal & {
    margin-top: ${lw(18)};
  }
  & > * {
    flex: 1 1 0;
  }
`

const CloseButtonContainer = styled.div`
  width: 2rem;
  height: auto;
  padding: 1rem;
  cursor: pointer;
`

const CatalogLink = styled.a`
  display: block;
  font-size: 0.5625rem;
  padding:  1rem 0.1875rem;
  letter-spacing: 0.05em;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.125rem;
  }
`

export type LayoutType = 'horizontal' | 'vertical'
export type Side = 'left' | 'right'

type PopupProps = {
  open: boolean
  onClose: () => void
  products: Product[]
  catalogLink: string
  layout: LayoutType
  columns: number
  side: Side
}
export const Popup: FunctionComponent<PopupProps> = ({products, catalogLink, open, onClose, layout, columns, side}) => {
  const [selected, setSelected] = useState<string>(products[0].name)
  const selectedProgram = products.find(p => p.name === selected)
  const scrollOuterRef = useRef<HTMLDivElement>(null)
  const [firstOpen, setFirstOpen] = useState(false)

  useEffect(() => {
    if(!firstOpen && open){
      setFirstOpen(true)
    }
    if(scrollOuterRef.current){
      scrollOuterRef.current.scrollTo({top: 0})
    }
  }, [open])

  return <Outer className={classNames(layout, {open}, side)}>
    <Inner>
      {
        firstOpen && <>
          <ListOuter>
            <PreviewContainer>
              <Image src={selectedProgram?.image80 ?? ''} width={100} height={100} lazy/>
            </PreviewContainer>
            <ProductListContainer>
              <ProductList
                products={products}
                columns={columns}
                onSelect={setSelected}
                onThumbnailClick={setSelected}
                linkTarget="label"
                selected={selected}
                scrollOuterRef={scrollOuterRef}
              />
            </ProductListContainer>
          </ListOuter>
          <CatalogLink href={catalogLink} target="_blank">デジタルカタログを見る ＞</CatalogLink>
        </>
      }
    </Inner>
    <CloseButtonContainer onClick={() => onClose()}>
      <SvgCloseButton />
    </CloseButtonContainer>
  </Outer>
}