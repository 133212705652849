import * as React from "react";
import type { SVGProps } from "react";
const SvgLeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={5.061}
    height={8.707}
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 5.061 8.707"
    {...props}
  >
    <path
      fill="none"
      stroke="currentcolor"
      strokeMiterlimit={10}
      d="m4.707.354-4 4 4 4"
    />
  </svg>
);
export default SvgLeftArrow;
