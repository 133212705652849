import React, { createContext, FunctionComponent, ReactNode, useContext, useState } from 'react'

type MobileMenuContext = {
  isOpen: boolean
  open: (callback: () => void) => void
  close: () => void
}
const MobileMenuContext = createContext<MobileMenuContext>({isOpen: false, open: () => {}, close: () => {}})

type MobileMenuContextProviderProps = {
  children?: ReactNode
}
export const MobileMenuContextProvider: FunctionComponent<MobileMenuContextProviderProps> = ({children}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [callback, setCallback] = useState<{fn: (() => void) | undefined}>({fn: undefined})
  return <MobileMenuContext.Provider value={{
    isOpen,
    open: newCallback => {
      setIsOpen(true)
      if(callback.fn){
        callback.fn()
      }
      setCallback({fn: newCallback})
    },
    close: () => {
      setIsOpen(false)
      if(callback.fn){
        callback.fn()
      }
      setCallback({fn: undefined})
    }
  }}>
    {children}
  </MobileMenuContext.Provider>
}

export const useMobileMenu = () => {
  const context = useContext(MobileMenuContext)
  return context
}