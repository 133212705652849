import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { useBreakpoint } from '../hooks/use-break-point'
import { StaticLiquidDiv } from './liquid-div'
import { MobileConcept } from './mobile-concept'
import { MobileCover } from './mobile-cover'
import { usePopup } from './popup-context-provider'
import { FaithSection, ReserveSection, FineSection } from './product-section'
import { Top } from './top'
import { mlw } from '../utils/lw'

const Outer = styled.div`
  padding-top: calc(80px + 4.5rem);
  @media (max-width: 1024px){
    padding-top: calc(68px + 4.5rem);
  }
  @media (max-width: 768px){
    padding-top: calc(60px + 4.5rem);
  }
  @media (max-width: 50rem){
    padding-top: calc(60px + 3.5rem);
  }
`

const VideoContainer = styled.div`
  margin: 4rem 0 0;
  img, video {
    display: block;
    width: 100%;
    height: auto;
  }
`
const MobileCoverContainer = styled.div``
const TextOuter = styled.div`
  margin-bottom: 4rem;
`

const Text = styled.div`
  font-size: min(${mlw(9)}, 1rem);
  font-weight: 300;
  letter-spacing: 0.035em;
  padding: 1rem 1rem 0;
  line-height: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 1rem;
`
const BorderOpen = styled.div`
  position: relative;
  margin: 1rem 1rem 0.2rem ;
  border-bottom: 1px solid;
  color: #848484;
  pointer-events: none;

  &::before,
  &::after {
    position: absolute;
    left: 50%;
    content: "";
    height: 0;
    width: 0;
  }

  &::before {
    border: 0.6375rem solid;
    border-color: transparent;
    border-top-color: #848484;
    margin-left: -0.6375rem;
    top: 0.02rem;
  }

  &::after {
    border: 0.6375rem solid;
    border-color: transparent;
    border-top-color: #c8c6c3;
    margin-left: -0.6375rem;
    top: -0.07rem;
  }
`

const BorderClose = styled.div`
  position: relative;
  margin: 0 1rem  ;
  border-bottom: 1px solid;
  color: #848484;

  &::before,
  &::after {
    position: absolute;
    left: 50%;
    content: "";

  }

  &::before {
    border: 0.6375rem solid;
    border-color: transparent;
    border-bottom-color: #848484;
    margin-left: -0.6375rem;
    bottom: -1px;
  }

  &::after {
    border: 0.6375rem solid;
    border-color: transparent;
    border-bottom-color: #c8c6c3;
    margin-left: -0.6375rem;
    bottom: -0.15rem;
  }
`

const ReadMore = styled.div`
  text-align: center;
  padding-top: 1rem;
  cursor: pointer;
  user-select: none;
  & span {
    font-size: 0.75rem;
    color: #848484;
    border-bottom: 1px solid #848484;
    font-weight: 700;
    letter-spacing: 0.03em;
  } 
`

type ContentsProps = {

}
export const Contents: FunctionComponent<ContentsProps> = () => {
  const isMobile = useBreakpoint('50rem')
  const [open, setOpen] = useState(false)
  return <Outer>
    {
      isMobile ? <>
        <StaticLiquidDiv fadeIn>
          <MobileConcept />
        </StaticLiquidDiv>
        <StaticLiquidDiv fadeIn fadeInDelay="0.2s">
          <VideoContainer>
            <video src="/pickup/process_100/videos/RKMV0004f.mp4" poster="/pickup/process_100/videos/RKMV0004f.jpg" width={375} height={210}  autoPlay loop muted playsInline/>
          </VideoContainer>
        </StaticLiquidDiv>
        <TextOuter>
          <Text style={{WebkitLineClamp:open ? undefined:5}}>
            Daybreak. Wind. Imagination. Brilliance. Bounce. Earth. Breath.
            Feeling. Space. Passion. Encounter. World. Embrace. Mist. Reflection.
            Sound. Prayer. Coincidence. Scent. Sensation. Senses. Movement.
            Forest. Song. Self. Overlap. Touch. Cloud. View. Summer rain. Gaze.
            Sky. Devotion. Season. Rainfall. Color. Wave. Purification. Humidity.
            Polish. Dance. Walk. Birth. Lucidity. Spirit. Look. Sleep. Stone.
            Preparation. Memory. Quiet. Depth. Music. Interest. Flavor. Grace.
            Freedom. Journey. Warmth. Smile. Moon. Whisper. Life. Playfulness.
            Crystal. Delicious. Fleeting. Sacred. Clear skies. Tale. Attention. Time.
            Friend. Bittersweet. Culture. Completion. Expression. Gift. Choice.
            Light. Snowy. Beloved. Liberation. Essence. Calm. Shadow. Nobility.
            Surge. Sun. Charm. Water. Transparent. Landscape. Return.
            Remembrance. Linger. Reverberation. Flow. Sea. Fertile.
            The twinkle of the stars that moves "me"
          </Text>
          {
            open ?  <BorderClose></BorderClose>:<BorderOpen></BorderOpen>
          }
          <ReadMore onClick={() => {
            setOpen(!open)
          }}><span>{open ? "CLOSE":"READ MORE"}</span></ReadMore>
        </TextOuter>
        <MobileCoverContainer>
          <StaticLiquidDiv fadeIn fadeInDelay="0.2s">
            <MobileCover
              label="Publication Catalog :"
              imgSrc="/pickup/process_100/images/mobile_cover_reserve_01.jpg"
              imgSrc2="/pickup/process_100/images/mobile_cover_reserve_02.png"
              title="RéSERVE"
              years="2024-2027"
              patterns="7"
              colors="19"
              target="reserve"
            />
          </StaticLiquidDiv>
          <StaticLiquidDiv fadeIn fadeInDelay="0.2s">
            <MobileCover
              imgSrc="/pickup/process_100/images/mobile_cover_fine_01.jpg"
              imgSrc2="/pickup/process_100/images/mobile_cover_fine_02.png"
              title="FINE"
              years="2023-2026"
              patterns="10"
              colors="33"
              target="fine"
              isNew
            />
          </StaticLiquidDiv>
          <StaticLiquidDiv fadeIn fadeInDelay="0.2s">
            <MobileCover
              imgSrc="/pickup/process_100/images/mobile_cover_faith_01.jpg"
              imgSrc2="/pickup/process_100/images/mobile_cover_faith_02.png"
              title="FAITH"
              subtitle="CONTRACT"
              years="2022-2025"
              patterns="19"
              colors="91"
              target="faith"
            />
          </StaticLiquidDiv>
        </MobileCoverContainer>
        <StaticLiquidDiv fadeIn>
          <VideoContainer>
            <video src="/pickup/process_100/videos/RKMV0003.mp4" poster="/pickup/process_100/videos/RKMV0003.jpg" width={375} height={210}  autoPlay loop muted playsInline/>
          </VideoContainer>
        </StaticLiquidDiv>
      </> : <>
        <Top />
      </>
    }
    <ReserveSection />
    <FineSection />
    <FaithSection />
  </Outer>
}