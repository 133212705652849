import * as React from "react";
import type { SVGProps } from "react";
const SvgCloseButton = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27.318}
    height={27.318}
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 27.318 27.318"
    {...props}
  >
    <path
      fill="none"
      stroke="currentcolor"
      strokeMiterlimit={10}
      strokeWidth={0.45}
      d="m27.159.159-27 27"
      data-name="Line 69"
    />
    <path
      fill="none"
      stroke="currentcolor"
      strokeMiterlimit={10}
      strokeWidth={0.45}
      d="m27.159 27.159-27-27"
      data-name="Line 70"
    />
  </svg>
);
export default SvgCloseButton;
