import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgLogo from '../generated/svg/logo'
import SvgLogoWithCopy from '../generated/svg/logo-with-copy'
import SvgLogoWithCopy2 from '../generated/svg/logo-with-copy2'

const Outer = styled.div`
  text-align: center;
  font-size: 0.6875rem;
  letter-spacing: 0.15em;
  white-space: nowrap;
 `

const Logo = styled.div`
  display: block;
  padding: 0 3rem 1.25rem;
  svg{
    width: 10rem;
    height: auto;
  }
`

const SeriesTitle = styled.div`
  font-weight: 600;
  padding-bottom: 3rem;
  font-size: 0.8125rem;
  letter-spacing: 0.2em;
`

const Detail = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 300;
`

const Year = styled.div`
  position: relative;
  padding: 0 0.75rem;

  &::after {
    position: absolute;
    content: "";
    width: 1px;
    right: 0;
    top: 15%;
    height: 80%;
    background-color: currentColor;
  }
`

const Patterns = styled.div`
  position: relative;
  padding: 0 0.75rem;

  &::after {
    position: absolute;
    content: "";
    width: 1px;
    right: 0;
    top: 15%;
    height: 80%;
    background-color: currentColor;
  }
`

const Colors = styled.div`
  padding: 0 0.75rem;
`
type SeriesHeader2Props = {
  patterns: string;
  colors: string;
  seriesTitle?: string;
  years: string;
  logoWithCopy?: boolean
  logoWithCopy2?: boolean
}
export const SeriesHeader2: FunctionComponent<SeriesHeader2Props> = ({years, patterns, colors, seriesTitle, logoWithCopy, logoWithCopy2}) => {
  return <Outer>
    <Logo>
      {
        logoWithCopy ? <SvgLogoWithCopy /> : logoWithCopy2 ? <SvgLogoWithCopy2 /> : <SvgLogo />
      }
    </Logo>
    <SeriesTitle>{seriesTitle}</SeriesTitle>
    <Detail>
      <Year>{years}</Year>
      <Patterns>{patterns} PATTERNS</Patterns>
      <Colors>{colors} COLORS</Colors>
    </Detail>
  </Outer>
}