import React, { ComponentProps, useEffect, useRef, useState } from 'react'
import { forwardRef, FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { lw, mlw } from '../utils/lw'
import { mergeRefs } from 'react-merge-refs'
import classNames from 'classnames'

type LiquidDivProps = {
  width?: number,
  height?: number,
  left?: number,
  right?: number,
  top?: number,
  bottom?: number
  mobileWidth?: number,
  mobileHeight?: number,
  mobileLeft?: number,
  mobileRight?: number,
  mobileTop?: number,
  mobileBottom?: number,
  children?: ReactNode,
  id?: string,
  className?: string,
  fadeIn?: boolean,
  fadeInDelay?: string
}
const StaticLiquidDivOuter = styled.div<LiquidDivProps>`
  opacity: 0;
  transform: translateY(4rem);
  transition: 0.8s ${({fadeInDelay}) => fadeInDelay} transform, 0.8s ${({fadeInDelay}) => fadeInDelay} opacity;
  &.show {
    transform: none;
    opacity: 1;
  }


  ${({width}) => typeof width === 'number' && css`
    width: ${lw(width)};
  `}
  ${({height}) => typeof height === 'number' && css`
    height: ${lw(height)};
  `}
  ${({left}) => typeof left === 'number' && css`
    left: ${lw(left)};
  `}
  ${({right}) => typeof right === 'number' && css`
    right: ${lw(right)};
  `}
  ${({top}) => typeof top === 'number' && css`
    top: ${lw(top)};
  `}
  ${({bottom}) => typeof bottom === 'number' && css`
    bottom: ${lw(bottom)};
  `}
  @media (max-width: 50rem){
    ${({mobileWidth}) => typeof mobileWidth === 'number' && css`
      width: ${mlw(mobileWidth)};
    `}
    ${({mobileHeight}) => typeof mobileHeight === 'number' && css`
      height: ${mlw(mobileHeight)};
    `}
    ${({mobileLeft}) => typeof mobileLeft === 'number' && css`
      left: ${mlw(mobileLeft)};
    `}
    ${({mobileRight}) => typeof mobileRight === 'number' && css`
      right: ${mlw(mobileRight)};
    `}
    ${({mobileTop}) => typeof mobileTop === 'number' && css`
      top: ${mlw(mobileTop)};
    `}
    ${({mobileBottom}) => typeof mobileBottom === 'number' && css`
      bottom: ${mlw(mobileBottom)};
    `}
  }
`

export const StaticLiquidDiv: FunctionComponent<LiquidDivProps> = forwardRef<HTMLDivElement, LiquidDivProps>((props, ref) => {
  const [show, setShow] = useState(false)
  const outerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const outer = outerRef.current
    if(outer){
      const listener = () => {
        const rect = outer.getBoundingClientRect()
        if(rect.y < (window.innerHeight)){
          setShow(true)
          window.removeEventListener('resize', listener)
          window.removeEventListener('scroll', listener)
        }
      }
      window.addEventListener('resize', listener)
      window.addEventListener('scroll', listener)
      listener()
      return () => {
        window.removeEventListener('resize', listener)
        window.removeEventListener('scroll', listener)
      }
    }
  })
  return <StaticLiquidDivOuter ref={mergeRefs([ref, outerRef])} {...props} className={classNames({show: !props.fadeIn || show, fadeIn: props.fadeIn }, props.className)}>
    {props.children}
  </StaticLiquidDivOuter>
})

export const RelativeLiquidDiv = styled(StaticLiquidDiv)`
  position: relative;
`

export const LiquidDiv = styled(StaticLiquidDiv)`
  position: absolute;
`