import classNames from 'classnames'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import SvgActiveSpeaker from '../generated/svg/active-speaker'
import SvgInactiveSpeaker from '../generated/svg/inactive-speaker'
import SvgLogo from '../generated/svg/logo'
import SvgMenuButton from '../generated/svg/menu-button'
import SvgMenuCloseButton from '../generated/svg/menu-close-button'
import { useBreakpoint } from '../hooks/use-break-point'
import { lw, mlw } from '../utils/lw'
import { scrollTo } from '../utils/scroll-to'
import { MobileMenu } from './mobile-menu'
import { useMobileMenu } from './mobile-menu-context-provider'

const Outer = styled.div`
  background-color: #C8C6C3E5;
  border-bottom: 1px solid #717071;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  @media (max-width: 1024px){
    padding-left: 15px;
    padding-right: 15px;
  }
`

const Inner = styled.div`
  min-width: 960px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${lw(50)};
  @media (max-width: 1024px){
    width: auto;
    min-width: 0;
    margin: 0 1.3% 0 0;
  }
`


const LogoContainer = styled.a`
  display: block;
  svg {
    margin-top: 0.125rem;
    height: 1rem;
    width: auto;
    display: block;
  }
`

const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NavigatorOuter = styled.div`
  display: flex;
  align-items: center;
`

const Navigator = styled.div`
  display: flex;
`

const NavigatorLabel = styled.div`
  align-self: flex-end;
  font-size: 0.5rem;
  padding: 1.5rem 1.8rem 1.5rem 1.5rem;
`

const NavigatorItem = styled.a`
  padding: 0.2rem 1.8rem;
  position: relative;
  font-weight: 700;
  letter-spacing: 0.075em;
  font-size: small;
  display: grid;
  place-items: center;
  grid-template-columns: 2.5rem;
  transition: color 0.4s;
  &:hover,
  &.current {
    color: white;
  }
  &::after {
    display: block;
    position: absolute;
    content: "";
    width: 1px;
    right: 0;
    top: 0;
    height: 100%;
    background-color: #231815;
  }
  &:first-child::before {
    display: block;
    position: absolute;
    content: "";
    width: 1px;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #231815;
  }
`

const SpeakerButtonContainer = styled.div`
  margin-left: 2.5rem;
  padding: 0.25rem;
  cursor: pointer;
  svg {
    height: 2rem;
    width: auto;
    display: block;
  }
`

const ActiveSpeakerButtonContainer = styled(SpeakerButtonContainer)`

`

const InactiveSpeakerButtonContainer = styled(SpeakerButtonContainer)`

`

const MobileMenuButtonContainer = styled.div`
  width: 2rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  svg {
    display: block;
  }
`

const MobileMenuConiatner = styled.div`

`

type HeaderProps = {

}
export const Header: FunctionComponent<HeaderProps> = () => {
  const [speakerIsActive, setSpeakerIsActive] = useState(false)
  const isMobile = useBreakpoint('50rem')
  const {open, close, isOpen} = useMobileMenu()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [current, setCurrent] = useState<string>()
  const audioRef = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    const listener = () => {
      const targets = ['reserve', 'fine', 'faith']
      let current: string | undefined
      targets.forEach(target => {
        const targetElement = document.getElementById(target)
        if(targetElement){
          const rect = targetElement.getBoundingClientRect()
          if(rect.top < window.innerWidth / 2){
            current = target
          }
        }
      })
      setCurrent(current)
    }
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  })
  useEffect(() => {
    const audio = audioRef.current
    if(audio){
      fetch('/pickup/process_100/sounds/sound.mp3', {
        credentials: 'same-origin',
      })
        .then(res => res.blob())
        .then(data => {
          audio.src = URL.createObjectURL(data)
        })
    }
  }, [])

  const toggleSound = () => {
    setSpeakerIsActive(v => !v)
    const audio = audioRef.current
    if(audio){
      if(speakerIsActive){
        audio.pause()
      } else {
        audio.play()
      }
    }
  }
  return <Outer>
    <Inner>
      <audio ref={audioRef} hidden loop/>
      <LogoContainer href="#" onClick={scrollTo}>
        <SvgLogo />
      </LogoContainer>
      <RightContainer>
        {
          isMobile ? <>
          </> : <>
            <NavigatorOuter>
              <NavigatorLabel>Publication Catalog:</NavigatorLabel>
              <Navigator>
                <NavigatorItem href="#reserve" className={classNames({current: current === 'reserve'})} onClick={scrollTo}>RéSERVE</NavigatorItem>
                <NavigatorItem href="#fine" className={classNames({current: current === 'fine'})} onClick={scrollTo}>FINE</NavigatorItem>
                <NavigatorItem href="#faith" className={classNames({current: current === 'faith'})} onClick={scrollTo}>FAITH</NavigatorItem>
              </Navigator>
            </NavigatorOuter>
          </>
        }
        {
          speakerIsActive ?
          <ActiveSpeakerButtonContainer onClick={toggleSound}>
            <SvgActiveSpeaker />
          </ActiveSpeakerButtonContainer> :
          <InactiveSpeakerButtonContainer onClick={toggleSound}>
            <SvgInactiveSpeaker />
          </InactiveSpeakerButtonContainer>
        }
        {
          isMobile && <>
            <MobileMenuButtonContainer onClick={() => {
              if(isOpen){
                close()
              } else {
                setMenuIsOpen(true)
                open(() => {
                  setMenuIsOpen(false)
                })
              }
            }}>
              {
                isOpen ? <SvgMenuCloseButton /> : <SvgMenuButton />
              }
            </MobileMenuButtonContainer>
            <MobileMenuConiatner>
              <MobileMenu open={menuIsOpen} onClose={close}/>
            </MobileMenuConiatner>
          </>
        }
      </RightContainer>
    </Inner>
  </Outer>
}