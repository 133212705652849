import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgLogo from '../generated/svg/logo'

const Outer = styled.div`
  width: max-content;
`

const Text = styled.div`
  font-size: 0.75rem;
  line-height: 2.6;
  letter-spacing: 0.1em;
  font-family: 'Noto Sans JP';
  font-weight: 300;
`
type ConceptProps = {

}
export const Concept: FunctionComponent<ConceptProps> = () => {
  return <Outer>
    <Text>
      process#100は、「個性と洗練」をコンセプトに<br />
      デザインから仕上げまで、プロセスを大切につくられた<br />
      サンゲツのプライベートブランド。<br />
      ゆるぎない美意識をもつ人々に、<br />
      洗練された上質な暮らしを提案します。
    </Text>
  </Outer>
}