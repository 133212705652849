import React, { ImgHTMLAttributes } from 'react';
import { HTMLAttributes } from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const webpSupport = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0

const Picture = styled.picture`
  display: contents;
`

const Img = styled.img`
  display: block;
`

export type ImageProps = {
  lazy?: boolean,
  webp?: boolean,
  src: string,
} & ImgHTMLAttributes<HTMLImageElement>
export const Image: FunctionComponent<ImageProps> = ({src, alt, width, height, lazy = false, webp = true, className, ...props}) => {
  return <Picture className={className}>
    <Img src={webpSupport ? `${src.replace(/\..*$/, '')}.webp` : src} width={width} height={height} alt={alt} loading={lazy ? 'lazy' : 'eager'} {...props}/>
  </Picture>
}

const Outer = styled.div`
  position: relative;
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
const ProxyCanvas = styled.canvas`
  display: block;
  width: 100%;
`

export const FixedRatioImage: FunctionComponent<ImageProps> = ({src, alt, width, height, webp}) => {
  return <Outer>
    <ProxyCanvas width={width} height={height}/>
    <Image src={src} width={width} height={height} alt={alt} webp={webp}/>
  </Outer>
}