import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { ImageData } from '../types/image-data'
import { lw } from '../utils/lw'
import { scrollTo } from '../utils/scroll-to'
import { Image } from './image'
import { LiquidDiv } from './liquid-div'
import { LiquidImg } from './liquid-img'

const Outer = styled.div`
`


const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  letter-spacing: 0.075em;
  gap: 0.4rem;
  font-weight: 300;
`

const LabelRow = styled.div`
  display: flex;
  gap: 0.3rem;
`

const Title = styled.div`
  font-size: 0.875rem;
  font-weight: 700;

`

const Subtitle = styled.div`
  font-size: 0.75rem;
  margin-top: 0.25rem;
`

const Label = styled.div`
  font-size: 0.75rem;
  margin-top: 0.25rem;
`

const YearsRow = styled.div`
  font-size: 0.75rem;
  display: flex;
`

const Years = styled.div`
  margin-top: 0.125rem;
`

const NewLabel = styled.div`
  color: #fff;
  span{
    font-weight: 700;
    margin: 0 0.3rem;
  }
  margin: 0 0.3rem;
`

const BackgroundContainer = styled.div`
  position: relative;
`

const Background = styled(Image)`
  img {
    width: 100%;
    height: auto;
    display: block;
    transition: 0.4s opacity;
    ${Outer}:hover & {
      opacity: 0;
    }
  }
`

const HoverBackground = styled(Image)`
  img {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: 0.5s;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    transition: 0.7s opacity;
    ${Outer}:hover & {
      opacity: 1;
    }
  }
`


type CoverProps = {
  title: string,
  subtitle: string,
  years: string,
  image: ImageData,
  hoverImage: ImageData,
  isNew?: boolean
  target: string
}
export const Cover: FunctionComponent<CoverProps> = ({title, subtitle, years, image, hoverImage, isNew, target}) => {
  return <Outer as="a" href={`#${target}`} onClick={scrollTo}>
    <TextContainer>
      <Title>{title}</Title>
      <LabelRow>
        {
          subtitle && <Subtitle>{subtitle}</Subtitle>
        }
        <Label>WALLCOVERLINGS</Label>
      </LabelRow>
      <YearsRow>
          <Years>{years}</Years>
          {
            isNew &&
            <NewLabel>|<span>NEW</span>|</NewLabel>
          }
      </YearsRow>
    </TextContainer>
    <BackgroundContainer>
      <Background {...image} />
      <HoverBackground {...hoverImage} />
    </BackgroundContainer>
  </Outer>
}